import React from 'react';
import axios from 'axios';
import { adalApiFetch } from './AdalConfig';

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  state = {
    user: {
      name: '',
      roles: [],
    },
  };

  componentDidMount = async () => {
    try {
      const { data: user } = await adalApiFetch(axios.get, '/api/user/GetUser', {});
      this.setState({ user });
    } catch (error) {}
  };

  render() {
    const { user } = this.state;
    return <AuthContext.Provider value={{ user }}>{this.props.children}</AuthContext.Provider>;
  }
}

const AuthConsumer = AuthContext.Consumer;

export {AuthContext, AuthProvider, AuthConsumer };
