import React from 'react';
import { Route } from 'react-router-dom';

import Home from './components/pages/Home';
import BulkUploader from './components/pages/BulkUploader';

export const routes = (
  <div>
    <Route exact path="/" component={Home} />
    <Route exact path="/upload-bulk" component={BulkUploader} />
  </div>
);
