/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { ProfileIcon, XIcon } from '../icons';
import colors from '../../../styles/colors';

const inputWrap = {
  fontSize: 14,
  color: '#000000',
  display: 'inline-block',
  width: 376,
  verticalAlign: 'top',
};

export const inputStyles = {
  alignItems: 'center',
  backgroundColor: '#fff',
  border: `1px solid ${colors.gray}`,
  borderRadius: 2,
  caretColor: colors.blueSecondary,
  color: colors.darkGray,
  display: 'flex',
  fontSize: 14,
  height: 56,
  outline: 0,
  overflow: 'hidden',
  padding: '0px 24px',
  position: 'relative',
  textOverflow: 'ellipsis',
  transition: '.2s all',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  width: '100%',
  input: {
    width: '100%',
  },
  ':focus-within': { border: `1px solid ${colors.blueSecondary}` },
  'input::placeholder': { color: colors.gray },
};

const labelsWrap = {
  alignItems: 'flex-end',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 8,
  fontSize: 16,
};

export const labelStyles = {
  color: colors.grayMed,
  fontSize: 12,
  letterSpacing: 1,
  textTransform: 'uppercase',
};

const errorBorder = { border: `1px solid ${colors.redError}` };

const errorStyles = {
  color: colors.redError,
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: 0.5,
  marginTop: 4,
  display: 'block',
};

export default function TextInput({
  border,
  disabled,
  error,
  icon,
  inputOverrides,
  label,
  labelOverrides,
  placeholder,
  ...props
}) {
  return (
    <label css={[inputWrap, labelOverrides]}>
      <div css={labelsWrap}>
        <span css={labelStyles}>{label}</span>
      </div>
      <div css={[inputStyles, error && errorBorder, inputOverrides]}>
        {icon && <ProfileIcon />}
        <input placeholder={placeholder} {...props} />
      </div>
      {error && <span css={errorStyles}>{error}</span>}
    </label>
  );
}
