/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import colors from '../../styles/colors';

const baseStyles = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 700,
  color: colors.white,
  '@media(max-width: 500px)': {
    order: 2,
  },
  '@media print': { color: '#444' },
};

export default function Welcome({ name, styleoverrides }) {
  const mergedStyles = [baseStyles, styleoverrides && styleoverrides];
  return (
    <div css={mergedStyles}>
      <span css={{ fontWeight: 300, paddingRight: 3, '@media print': { display: 'none' } }}>Welcome </span>
      {name}
    </div>
  );
}
