/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core';

import colors from '../../styles/colors';

const wave = keyframes`
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-12px);
  }
`;

const loader = {
  display: 'inline-block',
  position: 'relative',
  textAlign: 'center',
  verticalAlign: 'top',
};

const dot = {
  border: `1px solid ${colors.blueSecondary}`,
  borderRadius: '50%',
  display: 'inline-block',
  height: 12,
  marginRight: 5,
  width: 12,
  animation: `${wave} 1.3s linear infinite`,
};

export default function LoaderAnimation() {
  return (
    <div css={loader}>
      <div css={dot} />
      <div css={[dot, { animationDelay: '-1.1s' }]} />
      <div css={[dot, { animationDelay: '-.9s' }]} />
    </div>
  );
}
