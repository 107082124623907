/** @jsx jsx */
import { jsx, Global } from '@emotion/core';

import { SignIcon } from '../ui/icons';
import Button from '../ui/Button';

const screen = {
  alignItems: 'center',
  background: 'rgba(51, 48, 77, 0.5)',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: '0',
  overflowY: 'hidden',
  position: 'fixed',
  top: '0',
  width: '100%',
  zIndex: 500,
};

const modalWrap = {
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: 5,
  boxShadow: ' 0 1px 0 0 #ecedf5, 0 1px 3px 0 rgba(231, 233, 241, 0.3), 0 15px 25px 0 rgba(51, 48, 77, 0.15)',
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 0 40px',
  width: 656,
};

const buttonsWrap = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 48,
  width: 384,
  button: { minWidth: 180, width: 180 },
};

export default function DeleteRecordModal({ handleClose, handleDelete }) {
  return (
    <div css={screen}>
      <Global styles={{ body: { overflow: 'hidden' } }} />
      <div css={modalWrap}>
        <SignIcon signName="Hey!" positionText="26" />
        <span css={{ fontWeight: 700, fontSize: 24, marginTop: 48 }}>Are you sure you want to delete this record</span>
        <div css={buttonsWrap}>
          <Button onClick={handleClose} secondary styleoverrides={{ fontSize: 12 }}>
            Cancel, No Way
          </Button>
          <Button onClick={handleDelete}>Yes, You Bet</Button>
        </div>
      </div>
    </div>
  );
}
