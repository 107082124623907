import React from 'react';
import NumberFormat from 'react-number-format';
import TextInput from './TextInput';

export default function InputNumber({ thousandSeparator, value, onChange, prefix, ...props }) {
  return (
    <NumberFormat
      thousandSeparator={thousandSeparator}
      value={value}
      onChange={onChange}
      prefix={prefix}
      customInput={TextInput}
      {...props}
    />
  );
}
