/** @jsx jsx */
import React, { Component } from 'react';
import { jsx } from '@emotion/core';
import axios from 'axios';
import { adalApiFetch, adalApiPost } from '../AdalConfig';
import DeleteRecordModal from '../Modals/DeleteRecordModal';
import { PrintIcon, DeleteIcon, VerifiedWaterMarkIcon } from '../ui/icons';
import FormatDate from '../../Utils/Index';
import Button from '../ui/Button';
import colors from '../../styles/colors';
import generatePDF from '../../Utils/generatePDF';
import fileToBase64 from '../../Utils/fileToBase64';
import AccountPDF from './AccountPDF';
import { last } from 'lodash';

// #region css

const panelWrap = {
  backgroundColor: colors.grayExLight,
  borderRadius: 3,
  marginTop: 32,
  ':last-child': { marginBottom: 40 },
  '@media print': {
    border: 'none !important',
    pageBreakInside: 'avoid !important',
    backgroundColor: '#fff !important',
  },
};

const panelHeader = {
  alignItems: 'center',
  backgroundColor: colors.grayDark,
  borderRadius: '3px 3px 0 0',
  color: colors.white,
  display: 'flex',
  fontWeight: 700,
  height: 112,
  justifyContent: 'space-between',
  padding: '0 64px',
  '@media print': { borderRadius: '3px 3px 0 0 !important', backgroundColor: colors.grayLight, color: colors.black },
};

const statusButton = {
  alignItems: 'center',
  borderRadius: 3,
  display: 'flex',
  fontSize: 12,
  height: 40,
  justifyContent: 'center',
  width: 120,
  '@media print': { display: 'none !important' },
};

const blackListButton = {
  backgroundColor: colors.grayLight,
};

const verifiedButton = {
  color: colors.blueSecondary,
  backgroundColor: colors.blueShade,
};

const verifiedIcon = {
  display: 'none',
  '@media print': {
    display: 'block !important',
    position: 'absolute !important',
    top: '12% !important',
    left: '50% !important',
    transform: 'translate(-50%) !important',
  },
};

const accontNumberStyles = {
  fontSize: 24,
  span: {
    color: colors.white,
    '@media print': { color: colors.black },
  },
};

// #endregion

const statusOptions = {
  verified: 'Verified',
  blacklist: 'Blacklist',
};

const deleteAccount = async accountID => {
  const options = {
    method: 'post',
  };
  await adalApiFetch(axios, `api/Accounts/DeleteAccount?accountID=${accountID}`, options);
};

export default class SearchResultsPanel extends Component {
  state = {
    accountInfo: {},
    isModalOpen: false,
    isPrinting: false,
    fileNumber: '',
    loading: false,
    success: false,
    failed: false,
  };

  componentDidMount() {
    const { info, handleChange } = this.props;
    this.setState({ accountInfo: info });
    handleChange(this.state);
  }

  componentDidUpdate(_, prevState) {
    const { handleChange } = this.props;
    const { accountInfo } = this.state;

    if (accountInfo !== prevState.accountInfo) {
      handleChange(prevState.accountInfo.id);
    }
  }

  handleDelete = async accountID => {
    const { accountInfo } = this.state;
    await deleteAccount(accountID);
    this.setState({
      accountInfo: accountInfo.id === accountID && {},
    });
  };

  handlePrint = async() => {
    const { info } = this.props;
    const { routingNumber} = info;
     adalApiPost(`/api/Accounts/runpatriotsearch`, {
      FileNumber: routingNumber,
      Payee: info.payee,
    }).then(
      this.setState({ isPrinting: true }, () => {
      window.print();
      this.setState({ isPrinting: false });
    }))
    };
 


  getXs = () => {
    const { info } = this.props;
    const { lastFour, totalDigits } = info;
    let xs = '';
    let i;
    for (i = 0; i < totalDigits - lastFour.toString().length; i += 1) {
      xs += 'X';
    }
    return xs;
  };

  handleSaveToWeb = async () => {
    const { info } = this.props;
    this.setState({ loading: true }, async () => {
      const blob = await generatePDF('verification.pdf');
      const base64 = await fileToBase64(blob);
      const strippedBase64 = base64.substr(base64.indexOf(',') + 1);
      adalApiPost(`api/Accounts/SaveToWeb`, {
        bytes: strippedBase64,
        fileNumber: this.state.fileNumber,
        payee: info.payee,
      }).then(response => {
        if (response.success) {
          this.setState({ loading: false, success: true });
        } else {
          this.setState({ loading: false, failed: true });
        }
      });
    });
  };

  render() {
    const { info, isAdmin, accountNumber } = this.props;
    const {
      routingNumber,
      lastFour,
      payee,
      furtherCredits,
      verifiedBy,
      status,
      verifiedAt,
      sourceFileNum,
      notes,
      totalDigits,
    } = info;
    const { isModalOpen, isPrinting } = this.state;
    return (
      <React.Fragment>
        <div css={[panelWrap, !isPrinting && { '@media print': { display: 'none !important' } }]}>
          <div css={panelHeader}>
            <p css={{ fontSize: 12 }}>ACCOUNT NUMBER</p>
            <p css={accontNumberStyles}>
              <span>
                {!accountNumber && <span>{this.getXs()}</span>}
                <span css={{ color: colors.white }}>{accountNumber}</span>
              </span>
            </p>
          </div>
          <div css={{ padding: 64 }}>
            {!status && <BlacklistPrintAnnouncement />}
            <div css={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
              {status && (
                <span css={verifiedIcon}>
                  <VerifiedWaterMarkIcon />
                </span>
              )}
              <div>
                <InfoItem header="ROUTING NUMBER" detail={routingNumber} />
                <InfoItem header="PAYEE NAME" detail={payee} />
                {furtherCredits && furtherCredits.length > 0 && (
                  <InfoItem
                    header={furtherCredits.length > 1 ? 'FURTHER CREDITS' : 'FURTHER CREDIT'}
                    styles={{ whiteSpace: 'break-spaces' }}
                    detail={furtherCredits.join('\n')}
                  />
                )}
              </div>
              <span css={[statusButton, status ? verifiedButton : blackListButton]}>
                {status ? statusOptions.verified : statusOptions.blacklist}
              </span>
            </div>
            <InfoItem header="VERIFIED BY" detail={verifiedBy} />
            <InfoItem header="DATE VERIFIED" detail={FormatDate(verifiedAt)} />
            <InfoItem header="SOURCE FILE NUMBER" detail={sourceFileNum} />
            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              <InfoItem header="Notes" detail={notes} styles={{ width: 370, marginBottom: 0 }} />
              <div
                css={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  button: { '@media print': { display: 'none !important' } },
                }}
              >
                <div style={{ position: 'relative' }}>
                  <input
                    css={{ height: 40, borderRadius: 5, paddingLeft: 16 }}
                    placeholder="File Number"
                    value={this.state.fileNumber}
                    onChange={e => this.setState({ fileNumber: e.target.value, success: false, failed: false })}
                  />
                  {/* Save to web is disabled if more than 6 further credits (as a temp preventative) because of an issue where the pdf is black since it overflows to second page this needs to be fixed */}
                  <button
                    onClick={() => this.handleSaveToWeb()}
                    disabled={this.state.loading || this.state.success || (furtherCredits && furtherCredits.length > 6)}
                    css={{
                      color: '#fff',
                      height: 40,
                      backgroundColor: this.state.success ? '#00CC41' : this.state.failed ? 'red' : '#424242',
                      borderRadius: 5,
                      marginRight: 16,
                      marginLeft: 8,
                      paddingRight: 8,
                      paddingLeft: 8,
                      width: 112,
                      '&:hover': {
                        backgroundColor: this.state.success ? '#00CC41' : this.state.failed ? 'red' : '#282828',
                      },
                    }}
                  >
                    {this.state.loading
                      ? 'SAVING...'
                      : this.state.success
                        ? 'SAVED'
                        : this.state.failed
                          ? 'FAILED'
                          : 'SAVE TO WEB'}
                  </button>
                </div>
                <button
                  onClick={() => {
                    this.handlePrint();
                  }}
                >
                  <PrintIcon />
                </button>
                {isAdmin &&
                  <button css={{ marginLeft: 16 }} onClick={() => this.setState({ isModalOpen: true })}>
                    <DeleteIcon />
                  </button>
                }
              </div>
            </div>
            {!status && <BlacklistPrintAnnouncement />}
          </div>
          {isModalOpen && (
            <DeleteRecordModal
              handleDelete={() => this.handleDelete(info.id)}
              handleClose={() => this.setState({ isModalOpen: false })}
            />
          )}
        </div>
        {this.state.loading && (
          <div
            css={{
              display: 'none',
              '@media print': {
                display: 'block !important',
              },
            }}
          >
            <AccountPDF
              isPrinting={true}
              accountNumber={accountNumber}
              lastFour={lastFour}
              status={status}
              verifiedBy={verifiedBy}
              statusOptions={statusOptions}
              verifiedAt={verifiedAt}
              totalDigits={totalDigits}
              routingNumber={routingNumber}
              payee={payee}
              furtherCredits={furtherCredits}
              sourceFileNum={sourceFileNum}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const infoItemWrap = {
  display: 'inline-block',
  marginBottom: 48,
  verticalAlign: 'top',
  width: 232,
  '@media print': { marginBottom: '20px !important' },
};

function InfoItem({ header, detail, styles }) {
  return (
    <div css={[infoItemWrap, styles]}>
      <div css={{ textTransform: 'uppercase', color: '#8989aa', marginBottom: 8 }}>{header}</div>
      <div>{detail}</div>
    </div>
  );
}

const blackListAnnouncement = {
  display: 'none',
  fontSize: 65,
  fontWeight: 700,
  lineHeight: '80px',
  marginBottom: 40,
  textAlign: 'center',
  '@media print': { display: 'block' },
};

function BlacklistPrintAnnouncement() {
  return <div css={blackListAnnouncement}>Blacklisted</div>;
}
