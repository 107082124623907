import { css } from 'emotion';

import colors from '../../../styles/colors';

export const containerStyles = isFocused => ({
  position: 'relative',
  verticalAlign: 'top',
  cursor: 'pointer',
});

export const inputStyles = isError => ({
  border: `1px solid ${colors.gray}`,
  borderRadius: 2,
  display: 'flex',
  height: 56,
  overflow: 'hidden',
  padding: '0px 24px 0 32px',
  textOverflow: 'ellipsis',
  transition: 'color .2s',
  whiteSpace: 'nowrap',
  div: { padding: 0, margin: 0 },
  ':focus-within': { border: `1px solid ${colors.blueSecondary}` },
});

export const openMenuStyles = css({
  background: 'white',
  border: `1px solid ${colors.blueSecondary}`,
  borderTop: 'none',
  borderRadius: '0 0 4px 4px',
  cursor: 'pointer',
  maxHeight: 450,
  overflow: 'scroll',
  padding: '9px',
  position: 'absolute',
  textAlign: 'left',
  top: '60px',
  width: '100%',
  zIndex: 100,
});

export const itemStyles = isFocus => ({
  backgroundColor: 'white',
  borderRadius: 4,
  color: colors.black,
  margin: 0,
  padding: '9px 16px',
  transition: 'background-color .3s ease-out, color .3s ease-out',
  ':hover': { backgroundColor: '#f1f1f6', cursor: 'pointer' },
});

export const searchglassStyles = isFocused =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    span: { display: 'none' },
  });

export const matchedStyles = css({ color: colors.blue });
