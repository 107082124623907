/** @jsx jsx */
import { jsx } from '@emotion/core';

import colors from '../../styles/colors';

import Logo from './Logo';
import Welcome from './Welcome';
import Initial from './Initial';
import { Link } from 'react-router-dom';

// #region CSS

const headerStyles = {
  alignItems: 'center',
  display: 'flex',
  height: 156,
  position: 'relative',
  backgroundColor: colors.grayDark,
  '@media print': {
    backgroundColor: '#fff',
    border: '1px solid #787694',
    borderRadius: 3,
    color: '#444',
    height: 84,
    marginTop: 24,
  },
};

const innerWrap = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 auto',
  maxWidth: 1144,
  padding: '0 24px',
  width: '100%',
};

const headerText = {
  color: colors.white,
  display: 'inline-flex',
  fontSize: 20,
  fontWeight: 600,
  letterSpacing: '.075em',
  marginLeft: 8,
  textTransform: 'uppercase',
  span: { paddingLeft: 6, fontWeight: 300 },
  '@media(max-width: 500px)': {
    flexDirection: 'column',
    span: { paddingLeft: 0 },
    lineHeight: '23px',
  },
  '@media print': { color: '#444' },
};

const rightSideWrap = {
  '@media(max-width: 500px)': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
};

const bulkUploadLink = {
  color: 'white',
  a: {
    color: 'white',
    textDecoration: 'underline',
  },
};

// #endregion

export default ({ user, appName }) => (
  <div css={headerStyles}>
    <div css={innerWrap}>
      <Link to="/">
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Logo />
          <h1 css={headerText}>
            Madison
            <span>{appName}</span>
          </h1>
        </div>
      </Link>
      <div css={rightSideWrap}>
        <Welcome name={user.name} />
        <Initial name={user.name} />
        {user.roles.includes('Admin') && (
          <div css={bulkUploadLink}>
            <Link to="/upload-bulk">Upload Accounts File</Link>
          </div>
        )}
      </div>
    </div>
  </div>
);
