/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import LoaderAnimation from '../LoaderAnimation';
import { RightCaretIcon, LargeSearchGlassIcon } from '../icons';
import colors from '../../../styles/colors';

const styles = {
  alignItems: 'center',
  border: `2px solid ${colors.grayDark}`,
  borderRadius: 5,
  caretColor: colors.blueSecondary,
  color: colors.grayDark,
  display: 'flex',
  fontSize: 20,
  height: 48,
  justifyContent: 'space-between',
  outline: 0,
  overflow: 'hidden',
  padding: '0 32px',
  position: 'relative',
  textOverflow: 'ellipsis',
  transition: '.2s border',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  width: '100%',
  input: { paddingLeft: 16 },
  'input::placeholder': {
    color: colors.grayDark,
    fontSize: 20,
  },
  svg: { color: colors.grayDark },
  '@media print': { display: 'none' },
};

const inputStyles = {
  height: '100%',
  width: '680px',
  caretColor: colors.blueSecondary,
};

const submitButton = {
  alignItems: 'center',
  color: colors.grayDark,
  cursor: 'pointer',
  display: 'inline-flex',
  fontSize: 12,
  fontWeight: 700,
  textTransform: 'uppercase',
  width: 64,
  zIndex: '80',
  span: { marginRight: 4 },
};

export default function SearchInput({
  placeholder,
  disabled,
  value,
  styleOverrides,
  handleSubmit,
  searchIcon,
  isLoading,
  ...props
}) {
  return (
    <label css={[styles, styleOverrides]}>
      <div css={{ ':focus-within': { svg: { color: colors.blueSecondary } } }}>
        {searchIcon && <LargeSearchGlassIcon />}
        <input
          css={inputStyles}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          {...props}
          onKeyDown={e => {
            e.key === 'Enter' && handleSubmit();
          }}
        />
      </div>
      {value && (
        <>
          {isLoading && (
            <div css={{ position: 'absolute', right: 100, top: 30 }}>
              <LoaderAnimation />
            </div>
          )}
          <div css={submitButton} onClick={handleSubmit}>
            <span>Submit</span> <RightCaretIcon />
          </div>
        </>
      )}
    </label>
  );
}
