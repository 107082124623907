import React from 'react';

import Circle from '../ui/Circle';
import colors from '../../styles/colors';

const initialStyles = {
  height: 40,
  width: 40,
  paddingTop: 1,
  marginLeft: 8,
  lineHeight: 1,
  backgroundColor: colors.blueSecondary,
  textTransform: 'uppercase',
  '@media(max-width: 500px)': {
    alignSelf: 'flex-end',
    marginBottom: 8,
    order: 1,
  },
};

const getInitials = name =>
  name
    .split(' ')
    .map(np => np[0])
    .filter((_, i, arr) => i === 0 || i === arr.length - 1)
    .map(l => l && l.toLocaleUpperCase())
    .join('');

export default ({ name }) => <Circle text={getInitials(name)} styleoverrides={initialStyles} />;
