/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import colors from '../../styles/colors';
import * as icons from './icons';

const baseStyles = {
  alignItems: 'center',
  backgroundColor: colors.blueSecondary,
  borderRadius: 5,
  color: colors.white,
  display: 'inline-flex',
  fontSize: 14,
  fontWeight: 900,
  height: 48,
  justifyContent: 'center',
  letterSpacing: 1,
  minWidth: 256,
  padding: '0 24px',
  textTransform: 'uppercase',
  transition: 'all .2s',
  '&:hover': { backgroundColor: colors.bluePrimary },
};

const secondaryStyles = {
  backgroundColor: colors.white,
  color: colors.blueSecondary,
  border: `1px solid ${colors.blueSecondary}`,
  '&:hover': {
    color: colors.bluePrimary,
    border: `1px solid ${colors.bluePrimary}`,
    backgroundColor: colors.white,
  },
};

export default function Button({ children, secondary, styleoverrides, icon, ...props }) {
  const Icon = icons[icon];
  return (
    <button
      {...props}
      css={[baseStyles, secondary && secondaryStyles, styleoverrides, icon && { justifyContent: 'space-between' }]}
    >
      {children}
      {icon && <Icon />}
    </button>
  );
}
