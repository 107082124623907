/** @jsx jsx */
import { jsx } from '@emotion/core';
import { VerifiedWaterMarkIcon } from '../ui/icons';
import FormatDate from '../../Utils/Index';
import colors from '../../styles/colors';

const panelWrap = {
  backgroundColor: colors.grayExLight,
  borderRadius: 3,
  marginTop: 32,
  ':last-child': { marginBottom: 40 },
  '@media print': {
    border: 'none !important',
    pageBreakInside: 'avoid !important',
    backgroundColor: '#fff !important',
  },
};

const panelHeader = {
  alignItems: 'center',
  backgroundColor: colors.grayDark,
  borderRadius: '3px 3px 0 0',
  color: colors.white,
  display: 'flex',
  fontWeight: 700,
  height: 64,
  justifyContent: 'space-between',
  padding: '0 24px',
  '@media print': { borderRadius: '3px 3px 0 0 !important', backgroundColor: colors.grayLight, color: colors.black },
};

const statusButton = {
  alignItems: 'center',
  borderRadius: 3,
  display: 'flex',
  fontSize: 12,
  height: 40,
  justifyContent: 'center',
  width: 120,
  '@media print': { display: 'none !important' },
};

const blackListButton = {
  backgroundColor: colors.grayLight,
};

const verifiedButton = {
  color: colors.blueSecondary,
  backgroundColor: colors.blueShade,
};

const verifiedIcon = {
  display: 'none',
  '@media print': {
    display: 'block !important',
    position: 'absolute !important',
    top: '12% !important',
    left: '50% !important',
    transform: 'translate(-50%) !important',
  },
};

const accontNumberStyles = {
  fontSize: 16,
  span: {
    color: colors.white,
    '@media print': { color: colors.black },
  },
};

const getXs = (lastFour, totalDigits) => {
  let xs = '';
  let i;
  for (i = 0; i < totalDigits - lastFour.toString().length; i += 1) {
    xs += 'X';
  }
  return xs;
};

export default function AccountPDF({
  isPrinting,
  accountNumber,
  lastFour,
  status,
  verifiedBy,
  statusOptions,
  verifiedAt,
  totalDigits,
  routingNumber,
  payee,
  furtherCredits,
  sourceFileNum,
}) {
  return (
    <div css={[panelWrap, !isPrinting && { '@media print': { display: 'none !important' } }]}>
      <div css={panelHeader}>
        <p css={{ fontSize: 12 }}>ACCOUNT NUMBER</p>
        <p css={accontNumberStyles}>
          <span>
            {!accountNumber && <span>{getXs(lastFour, totalDigits)}</span>}
            <span css={{ color: colors.white }}>{!accountNumber ? lastFour : accountNumber}</span>
          </span>
        </p>
      </div>
      <div css={{ padding: 16 }}>
        {!status && <BlacklistPrintAnnouncement />}
        <div css={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
          {status && (
            <span css={verifiedIcon}>
              <VerifiedWaterMarkIcon />
            </span>
          )}
          <div>
            <InfoItem header="ROUTING NUMBER" detail={routingNumber} />
            <InfoItem header="PAYEE NAME" detail={payee} />
            {furtherCredits && furtherCredits.length > 0 && (
              <InfoItem
                header={furtherCredits.length > 1 ? 'FURTHER CREDITS' : 'FURTHER CREDIT'}
                styles={{ whiteSpace: 'break-spaces' }}
                detail={furtherCredits.join('\n')}
              />
            )}
          </div>
          <span css={[statusButton, status ? verifiedButton : blackListButton]}>
            {status ? statusOptions.verified : statusOptions.blacklist}
          </span>
        </div>
        <InfoItem header="VERIFIED BY" detail={verifiedBy} />
        <InfoItem header="DATE VERIFIED" detail={FormatDate(verifiedAt)} />
        <InfoItem header="SOURCE FILE NUMBER" detail={sourceFileNum} />
        {!status && <BlacklistPrintAnnouncement />}
      </div>
    </div>
  );
}

const blackListAnnouncement = {
  display: 'none',
  fontSize: 65,
  fontWeight: 700,
  lineHeight: '80px',
  marginBottom: 40,
  textAlign: 'center',
  '@media print': { display: 'block !important' },
};

function BlacklistPrintAnnouncement() {
  return <div css={blackListAnnouncement}>Blacklisted</div>;
}

const infoItemWrap = {
  display: 'inline-block',
  marginBottom: 16,
  verticalAlign: 'top',
  width: 232,
  '@media print': { marginBottom: '16px  !important' },
};

function InfoItem({ header, detail, styles }) {
  return (
    <div css={[infoItemWrap, styles]}>
      <div css={{ textTransform: 'uppercase', color: '#8989aa', marginBottom: 8 }}>{header}</div>
      <div>{detail}</div>
    </div>
  );
}
