/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import colors from '../../styles/colors';
import * as icons from './icons';

const baseStyles = {
  alignItems: 'center',
  backgroundColor: colors.bluePrimary,
  borderRadius: '50%',
  color: colors.white,
  display: 'inline-flex',
  fontSize: 16,
  fontWeight: 'bold',
  height: 56,
  justifyContent: 'center',
  width: 56,
};
const smallStyles = {
  height: 48,
  width: 48,
};

export default function Circle({ small, text, icon, styleoverrides }) {
  const mergedStyles = [baseStyles, small && smallStyles, styleoverrides];
  const Icon = icons[icon];
  return <div css={mergedStyles}>{icon ? <Icon /> : text}</div>;
}
