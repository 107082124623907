/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { adalApiFetch } from '../AdalConfig';
import produce from "immer"

import SearchSelect from '../ui/SearchSelect';
import TextInput, { labelStyles } from '../ui/Inputs/TextInput';
import InputNumber from '../ui/Inputs/InputNumber';
import Button from '../ui/Button';
import AddRecordModal from '../Modals/AddRecordModal';
import { PlusIcon } from '../ui/icons';
import FurtherCredit from './components/FurtherCredit';

const statusOptions = [{ label: 'Verified', value: true }, { label: 'Blacklist', value: false }];

const modalStates = {
  success: 'The account has been added successfully',
  failure: 'Something went wrong, please try again',
};

const excludedErrors = {
  accountNumber: 'accountNumberError',
  notes: 'notesError',
};

const MAX_NOTE = 250;

const labelStyle = { marginTop: 32 };

const panelWrap = {
  margin: '40px auto 24px',
  maxWidth: 805,
  position: 'relative',
};

const DateInput = ({ value, onChange, error, onClick }) => (
  <TextInput value={value} onChange={onChange} error={error} onClick={onClick} />
);

const addAccount = async newAccount => {
  const tempNewAccount = {...newAccount, furtherCredits: newAccount.furtherCredits.map(fc=> fc.furtherCredit)} 
  try {
    const options = {
      method: 'post',
      data: tempNewAccount,
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const { data } = await adalApiFetch(
      axios,
      `/api/Accounts/InsertAccount?accountNum=${newAccount.accountNumber}`,
      options,
    );
    return data;
  } catch (err) {
    return err;
  }
};

const getAccount = async (searchString, furtherCredit )=> {
  const { data: accountsInfo } = await adalApiFetch(
    axios.get,
    `api/Accounts/SearchAccountsOrFurtherCredit?searchQuery=${furtherCredit || searchString}`,
    {},
  );
  return accountsInfo;
};
export default class AddNewAccountForm extends Component {
  state = {
    bankName: '',
    newAccountInfo: {
      accountHash: '',
      accountNumber: '',
      routingNumber: '',
      furtherCredits: [{furtherCredit: "", isDuplicate: false}],
      payee: '',
      status: true,
      verifiedBy: '',
      verifiedAt: new Date(),
      createdBy: '',
      sourceFileNum: '',
      notes: '',
      lastFour: 0,
      totalDigits: 0,
    },
    searchedAccountsInfo: [],
    accountNumberError: false,
    routingNumberError: false,
    furtherCreditExists: false,
    payeeError: false,
    verifiedByError: false,
    verifiedAtError: false,
    sourceFileNumError: false,
    notesError: false,
    modalIsOpen: false,
    modalText: '',
    isExistingAccount: false,
  };


  componentWillMount() {
    this.initialState = this.state;
  }

  componentDidMount() {
    const { user } = this.props;
    const { newAccountInfo } = this.state;

    this.setState({
      newAccountInfo: { ...newAccountInfo, createdBy: user.name },
    });
  }

  componentDidUpdate(_, prevState) {
    const { handleChange } = this.props;
    const { modalIsOpen, isExistingAccount } = this.state;
    if (modalIsOpen !== prevState.modalIsOpen && !modalIsOpen) {
      handleChange();
    }
    // if (isExistingAccount && isExistingAccount !== prevState.isExistingAccount) {
    //   this.changeErrorValue(true, excludedErrors.accountNumber);
    // }
  }

  onSubmit = exceededNote => {
    const { newAccountInfo } = this.state;
    if (newAccountInfo.furtherCredits.filter((fc) => fc.isDuplicate ).length > 0)
    {
      return false;
    }
   var furtherCredits = newAccountInfo.furtherCredits.filter((fc) => fc.furtherCredit )
    if (this.checkValidation(exceededNote)) {
      this.setState(
        {
          newAccountInfo: {
            ...newAccountInfo,
            furtherCredits,
            totalDigits: this.getTotalDigits(newAccountInfo.accountNumber),
            lastFour: this.getLastFourDigits(newAccountInfo.accountNumber),
          },
        },
        this.handleAddAccount,
      );
    }
  };

  changeFurtheCredit = (idx, value, label) => {
   const newState = produce(this.state, draft=> {
    draft.newAccountInfo.furtherCredits[idx][label] = value
   })
   this.setState(newState)
  }

  getBankName = async (routingNumber) => {
    const { data } = await adalApiFetch(
      axios.get,
      `/api/Accounts/GetBankName?routingNumber=${routingNumber}`,
    );
    this.setState({ bankName: data[0].data.name })
  };


  handleAddAccount = () => {
    const { newAccountInfo } = this.state;
    addAccount(newAccountInfo).then(result => {
      result = !result.toString().includes('Error');
      result && this.setState(this.initialState);
      this.setState({ modalIsOpen: true, modalText: result ? modalStates.success : modalStates.failure });
    });
  };

  handleClose = () => {
    this.setState(this.initialState);
  };

  onCancel = () => {
    this.setState(this.initialState);
    const { handleChange } = this.props;
    handleChange();
  };

  checkValidation = exceededNote => {
    const { newAccountInfo, isExistingAccount } = this.state;
    const { accountNumber, routingNumber, payee, verifiedBy, verifiedAt, sourceFileNum, furtherCreditError } = this.state.newAccountInfo;
    Object.entries(newAccountInfo).forEach(([key, value]) => {
      const errorItem = `${key}Error`;
      switch (errorItem) {
        case excludedErrors.accountNumber:
          if (value === '' || isExistingAccount) {
            this.changeErrorValue(true, errorItem);
          }
          break;
        case excludedErrors.notes:
          if (exceededNote) {
            this.changeErrorValue(true, errorItem);
          }
          break;
        default:
          this.changeErrorValue(!value, errorItem);
      }
    });

    return (
      !!accountNumber &&
      !isExistingAccount &&
      !exceededNote &&
      !!routingNumber &&
      !!payee &&
      !!verifiedBy &&
      !!verifiedAt &&
      !!sourceFileNum
    );
  };

  checkIfAccountExists = async (furtherCredit) => {
    const { newAccountInfo } = this.state;
    const { accountNumber, routingNumber } = newAccountInfo;
    let searchedAccountsInfo = [];
    this.setState({
      isExistingAccount: false,
    });
    if (!this.state.searchedAccountInfo) {
      searchedAccountsInfo = await getAccount(accountNumber, furtherCredit);
    }
    const accountExists = Object.entries(searchedAccountsInfo).length !== 0;
    this.setState({
      searchedAccountsInfo,
      isExistingAccount:
        (accountExists && searchedAccountsInfo.some(account => account.routingNumber.toString() === routingNumber)) ||
        false,
    });
  };

  changeErrorValue = (value, field) => {
    this.setState({
      [field]: value,
    });
  };

  getRidOfError = field => {
    this.setState({ [field]: false });
  };

  deleteFurtherCredit = (idx, value) => {
    this.setState(state => ({
      ...state,
      newAccountInfo: {
        ...state.newAccountInfo,
        furtherCredits: state.newAccountInfo.furtherCredits.slice().filter((_, i) => i !== idx)
      }
    }));
  }
  
  getTotalDigits = accountNumber => accountNumber.length;

  getLastFourDigits = accountNumber => (accountNumber.length > 4 ? accountNumber.slice(-4) : accountNumber);

  onChange = (field, value) => {
    const { newAccountInfo } = this.state;
    this.setState({
      newAccountInfo: { ...newAccountInfo, [field]: value },
    });
  };

  onDateChange = date => {
    const { newAccountInfo } = this.state;
    this.setState({
      newAccountInfo: { ...newAccountInfo, verifiedAt: date },
    });
  };

  handleRoutingNumberChange(e) {
    this.onChange('routingNumber', e.target.value);
  }

  render() {
    const {
      accountNumber,
      routingNumber,
      furtherCredits,
      payee,
      verifiedBy,
      verifiedAt,
      sourceFileNum,
      notes,
    } = this.state.newAccountInfo;
    const {
      accountNumberError,
      routingNumberError,
      furtherCreditError,
      payeeError,
      verifiedByError,
      verifiedAtError,
      sourceFileNumError,
      notesError,
      modalIsOpen,
      modalText,
      isExistingAccount,
    } = this.state;
    const maxNote = MAX_NOTE;
    const exceededNote = notes && notes.length > maxNote;
   
    const isFurtherCreditRoutingNumber = routingNumber === "121202211" || routingNumber === '021000021' || routingNumber === '231270353' || routingNumber === '021000089'

    return (
      <div css={panelWrap}>
        <h2 css={{ fontSize: 24, fontWeight: 700, marginBottom: 72 }}>New account</h2>
        <div>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextInput
             label="Account Number"
             value={accountNumber} 
             onChange={e => this.onChange('accountNumber', e.target.value)}
             error={accountNumberError && !isFurtherCreditRoutingNumber &&'Required'}
             onKeyUp={() => this.getRidOfError('accountNumberError')}
             onBlur={() => {
               this.checkIfAccountExists()
             }}
            />
             {this.state.bankName ? <div>{this.state.bankName}</div> : ''}
            <div>
              <InputNumber
                label="Routing Number"
                value={routingNumber}
                error={routingNumberError ? 'Required' : !isFurtherCreditRoutingNumber && isExistingAccount ? `Account and Routing number combination already exists` : ''}
                onKeyUp={() => this.getRidOfError('routingNumberError')}
                onBlur={() => {
                  !isFurtherCreditRoutingNumber ? this.checkIfAccountExists() : null
                 } }
                onChange={e => this.handleRoutingNumberChange(e)}
                
              />
            </div>
          </div>
          {(isFurtherCreditRoutingNumber) && 
            furtherCredits.map((f, idx) => {
              return (
              <div style={{position: 'relative'}}>
                <FurtherCredit 
                idx={idx}
                value={f}
                getAccount={getAccount}
                onChange={this.changeFurtheCredit}
                routingNumber={routingNumber}
                setGlobalFurtherCreditExists={(exists)=> this.setState({furtherCreditExists: exists})}
                deleteFurtherCredit={() => this.deleteFurtherCredit(idx, f)}
                furtherCreditError={furtherCreditError}
                />
                {idx === furtherCredits.length - 1 && <PlusIcon style={{ position: 'absolute', top: 65, right: 405 }} onClick={() => this.setState({ ...this.state, newAccountInfo: { ...this.state.newAccountInfo, furtherCredits: [...this.state.newAccountInfo.furtherCredits, {furtherCredit: "", isDuplicate: false}] } })} />}
              </div>
              )
            })

          }
          <div>
            <TextInput
              labelOverrides={{ marginTop: 15 }}
              label="Payee Name"
              placeholder="e.g. Ava Gregoraci"
              value={payee}
              onChange={e => this.onChange('payee', e.target.value)}
              error={payeeError && 'Required'}
              onKeyUp={() => this.getRidOfError('payeeError')}
            />
          </div>
          <div>
            <div css={{ width: 376, marginTop: 32 }}>
              <p css={{ ...labelStyles }}>Status</p>
              <SearchSelect
                onChange={value => {
                  this.onChange('status', value.value);
                }}
                options={statusOptions}
                defaultValue={statusOptions[0]}
              />
            </div>
          </div>
          <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <TextInput
              labelOverrides={labelStyle}
              label="Verified By"
              value={verifiedBy}
              onChange={e => this.onChange('verifiedBy', e.target.value)}
              error={verifiedByError && 'Required'}
              onKeyUp={() => this.getRidOfError('verifiedByError')}
            />
            <div css={{ width: 376 }}>
              <p css={{ ...labelStyles, marginBottom: 0 }}>Verified Date</p>
              <DatePicker
                onChange={this.onDateChange}
                selected={verifiedAt || null}
                onSelect={() => this.getRidOfError('verifiedAtError')}
                customInput={<DateInput error={verifiedAtError} />}
              />
            </div>
          </div>
          <TextInput
            labelOverrides={labelStyle}
            label="Source File Number"
            value={sourceFileNum}
            onChange={e => this.onChange('sourceFileNum', e.target.value)}
            error={sourceFileNumError && 'Required'}
            onKeyUp={() => this.getRidOfError('sourceFileNumError')}
          />
          <TextInput
            labelOverrides={(labelStyle, { width: '100%', marginTop: 24 })}
            label="Note"
            placeholder="Enter note"
            inputOverrides={{ height: 130, alignItems: 'flex-start', paddingTop: 16 }}
            value={notes}
            onChange={e => this.onChange('notes', e.target.value)}
            error={notesError && (exceededNote ? `Notes cannot exceed ${maxNote} characters` : notesError)}
            onKeyUp={() => this.getRidOfError('notesError')}
          />
          <div css={{ display: 'flex', justifyContent: 'flex-end', marginTop: 64 }}>
            <button css={{ marginRight: 64, color: '#787694' }} onClick={this.onCancel}>
              CANCEL
            </button>
            <Button data-test="submit" onClick={() => this.onSubmit(exceededNote)} styleoverrides={{ height: 64 }}>
              Add
            </Button>
          </div>
        </div>
        {modalIsOpen && <AddRecordModal handleClose={() => this.handleClose()} text={modalText} />}
      </div>
    );
  }
}
