import React, { Component } from 'react';
import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

const redirectUri = typeof window !== "undefined" ? window.location.origin + "/" : "";

export const adalConfig = {
  tenant: 'bbfeea83-3c0f-4fcc-838e-a0848f3fcb93',
  clientId: clientId,
  endpoints: {
    api: api,
  },
  cacheLocation: 'localStorage',
  redirectUri
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const adalApiGet = async url => {
    const response = await adalApiFetch(fetch, url, {});
    return await response.json();
}

export const adalApiPost = async (url, body) =>{
    const response = await adalApiFetch(fetch, url, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });

    return await response.json();
}


export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);
