import React from 'react';
import { injectGlobal } from 'emotion';
import otfReg from '../styles/fonts/SulSansRegular.otf';
import otfBold from '../styles/fonts/SulSansBold.otf';

injectGlobal`
    /* =Reset default browser CSS. Based on work by Eric Meyer: http://meyerweb.com/eric/tools/css/reset/index.html ----------------------------------------------------- */

    @font-face {
        font-family:"Sul";
        src:url("${otfReg}");
        font-style:normal;font-weight:400;
    }

    @font-face {
        font-family:"Sul";
        src:url("${otfBold}");
        font-style:bold;font-weight:700;
    }

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    font,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
        font-weight: inherit;
    }

    body,
    input,
    label,
    textarea,
    select,
    button {
        font-family: Sul, Arial, Helvetica, sans-serif;
    }

    body {
        line-height: 1;
    }

    ol,
    ul {
        list-style: none;
    }
    /* tables still need 'cellspacing="0"' in the markup */
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    input,
    select {
        margin-right: 0;
        margin-left: 0;
    }

    button {
        vertical-align: middle;
        outline: none;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }

    /* base styles */

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    html,
    body {
        -moz-osx-font-smoothing: grayscale;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-font-smoothing: antialiased;
        -webkit-overflow-scrolling: touch;
        -webkit-text-size-adjust: none;
        background-color: #ffffff;
        box-sizing: border-box;
        color: #424242;
        font-size: 14px;
        height: 100%;
        line-height: 21px;
        max-width: 100%;
        min-width: 320px;
        position: relative;
        width: 100%;
    }

    a {
        color: #424242;
        cursor: pointer;
        text-decoration: none;
    }

    b {
        font-weight: 600;
    }

    li {
        list-style: none;
    }
    img {
        display: block;
        max-width: 100%;
    }

    form {
        position: relative;
    }

    input,
    textarea {
        border: none;
        font-size: 14px;
        padding: 0;
        border-radius: 0;
        outline: none;
    }

    input:focus,
    textarea:focus {
        outline: none;
    }

    button,
    select {
        text-transform: none;
    }

    button {
        -webkit-appearance: button;
    }

    button[disabled],
    input[disabled] {
        cursor: default;
    }

    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    input,
    button,
    select,
    textarea {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #eee;
    }

    fieldset {
        padding: 0;
        margin: 0;
        border: 0;
        min-width: 0;
    }

    input[type='text']:focus {
        outline: 0;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-text-fill-color: white;
    }
    @media print {
        * {
            background-color: #fff;
            background: transparent;
            box-shadow: none !important;
            text-shadow: none !important;
        }

        html {
            overflow: hidden; 
            background-color: #fff;
        }

        body {
            overflow: hidden; 
            -webkit-print-color-adjust: exact;
            background-color: #fff;
            color-adjust: exact;
            margin: 0 auto;
            width: 670px;
        }
    }
`;

export default function GlobalStyles() {
  return null;
}
