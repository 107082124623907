/** @jsx jsx */
import { jsx } from '@emotion/core';

import colors from '../../styles/colors';

const footerWrap = {
  backgroundColor: colors.grayExLight,
  bottom: 0,
  marginTop: 24,
  position: 'absolute',
  width: '100%',
  '@media print': { display: 'none' },
};

const innerWrap = {
  alignItems: 'center',
  color: '#787694',
  display: 'flex',
  height: 156,
  justifyContent: 'space-between',
  margin: '0 auto',
  maxWidth: 1144,
  padding: '0 24px',
  textTransform: 'uppercase',
  ' a': { color: '#787694' },
};

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer css={footerWrap}>
      <div css={innerWrap}>
        <div>© COPYRIGHT {currentYear} MADISON</div>
        <div>
          <span
            css={{
              marginRight: 64,
              '@media(max-width: 768px)': { display: 'none' },
            }}
          >
            Privacy Policy
          </span>
          <span css={{ '@media(max-width: 500px)': { display: 'none' } }}>Terms of Use</span>
        </div>
      </div>
    </footer>
  );
}
