export default {
  // base color palette
  black: '#212121',
  grayDark: '#424242',
  grayMed: '#757575',
  gray: '#bdbdbd',
  grayLight: '#e0e0e0',
  grayExLight: '#f5f5f5',
  white: '#ffffff',
  // Dynamic colors
  bluePrimary: '#0026ca',
  blueSecondary: '#304ffe',
  blueShade: '#e8eaf6',
  redError: '#d50000',
};
