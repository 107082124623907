export default function generatePDF(fileName = '', selector = 'html') {
  const content = document.querySelector(selector).outerHTML;
  return fetch('https://mcres-html2pdf-service.azurewebsites.net', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      fileName: fileName || 'verify.pdf',
      data: content,
    }),
  })
    .then(res => {
      if (res.ok) {
        return res.blob();
      } else {
        throw new Error('an error has occoured when downloading');
      }
    })
    .catch(err => {
      if (err.name !== 'AbortError') return err;
    });
}
