import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';

import { runWithAdal } from 'react-adal';
import * as RoutesModule from './Router';
import GlobalStyles from './components/GlobalStyles';
import { AuthProvider } from './components/AuthContext';
import { authContext } from './components/AdalConfig';

let routes = RoutesModule.routes;
const DO_NOT_LOGIN = false;

runWithAdal(
  authContext,
  () => {
    function renderApp() {
      render(
        <>
          <GlobalStyles />
          <AuthProvider>
            <AppContainer>
              <BrowserRouter children={routes} />
            </AppContainer>
          </AuthProvider>
        </>,
        document.getElementById('app'),
      );
    }
    renderApp();
    if (module.hot) {
      module.hot.accept('./Router', () => {
        routes = require('./Router').routes;
        renderApp();
      });
    }
  },
  DO_NOT_LOGIN,
);
