/** @jsx jsx */
import {jsx} from "@emotion/core";
import React from 'react';
import {AuthConsumer} from '../AuthContext';
import Header from './Header';
import Footer from "./Footer";

const bodyWrap = {
    display: 'block',
    minHeight: '100vh',
    overflow: 'hidden',
    paddingBottom: '188px',
    position: 'relative',
    '@media print': {minHeight: 0},
};

const Layout = ({children}) => {
    return (
        <AuthConsumer>
            {({user}) => (
                <div css={bodyWrap}>
                    <Header appName="VERIFY" user={user}/>
                    {children}
                    <Footer/>
                </div>
            )}
        </AuthConsumer>
    )
}

export default Layout;
