/** @jsx jsx */
import { jsx } from '@emotion/core';

import Panel from '../ui/Panel';

export default function SearchAdvisoryPanels() {
  return (
    <div css={{ display: 'flex', marginTop: 24 }}>
      <Panel styleOverrides={{ lineHeight: 1.7, width: 'calc(50% - 12px)', marginRight: 24 }}>
        <h3 css={{ marginBottom: 16, fontSize: 16, fontWeight: 700 }}>To search by account number</h3>
        Use full account number only
        <br />
        Do not use any dashes or spaces
        <br />
        If the account starts with zeros and you can’t find the account with the zeros, try without the leading zeros
      </Panel>
      <Panel styleOverrides={{ lineHeight: 1.7, width: 'calc(50% - 12px)' }}>
        <h3 css={{ marginBottom: 16, fontSize: 16, fontWeight: 700 }}>To search by payee</h3>
        You can search by any part of the payee name
      </Panel>
    </div>
  );
}
