import React from 'react';
// hover color for tag is #000
export function BillingIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(1.09)"
      >
        <path d="M.364.364h13.09v15.272l-2.181-1.454-2.182 1.454-2.182-1.454-2.182 1.454-2.182-1.454-2.181 1.454zM3.273 10.545h3.636M9.818 10.545h.727" />
        <circle cx="6.909" cy="5.455" r="2.182" />
      </g>
    </svg>
  );
}

export function ProfileIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M21 11c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10z" />
        <path
          stroke="#C6C5DD"
          strokeLinejoin="round"
          d="M21 11c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10z"
        />
        <path
          fill="#F5F5F9"
          d="M8.023 16.68L5.04 18.308a2.664 2.664 0 0 0-.48.34A9.957 9.957 0 0 0 11 21a9.96 9.96 0 0 0 6.402-2.318 2.665 2.665 0 0 0-.527-.353L13.68 16.73a1.218 1.218 0 0 1-.673-1.09v-1.253c.09-.102.192-.234.302-.389a7.352 7.352 0 0 0 .993-2.001c.41-.127.712-.505.712-.955V9.705a.998.998 0 0 0-.335-.742V7.03S15.077 4.02 11 4.02s-3.68 3.01-3.68 3.01v1.934a.998.998 0 0 0-.334.742v1.338c0 .352.186.663.463.842a6.637 6.637 0 0 0 1.21 2.503v1.222c0 .446-.244.856-.636 1.07z"
        />
        <path
          stroke="#C6C5DD"
          strokeLinejoin="round"
          d="M8.023 16.68L5.04 18.308a2.664 2.664 0 0 0-.48.34A9.957 9.957 0 0 0 11 21a9.96 9.96 0 0 0 6.402-2.318 2.665 2.665 0 0 0-.527-.353L13.68 16.73a1.218 1.218 0 0 1-.673-1.09v-1.253c.09-.102.192-.234.302-.389a7.352 7.352 0 0 0 .993-2.001c.41-.127.712-.505.712-.955V9.705a.998.998 0 0 0-.335-.742V7.03S15.077 4.02 11 4.02s-3.68 3.01-3.68 3.01v1.934a.998.998 0 0 0-.334.742v1.338c0 .352.186.663.463.842a6.637 6.637 0 0 0 1.21 2.503v1.222c0 .446-.244.856-.636 1.07z"
        />
      </g>
    </svg>
  );
}

export function FormInputIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="388"
      height="76"
      viewBox="0 0 388 76"
    >
      <defs>
        <path id="b" d="M2 0h372a2 2 0 0 1 2 2v60a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
        <filter id="a" width="104.8%" height="128.1%" x="-2.4%" y="-10.9%" filterUnits="objectBoundingBox">
          <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter1" />
          <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2.5" />
          <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.912643151 0 0 0 0 0.905520665 0 0 0 0 0.945046769 0 0 0 0.29967731 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(6 4)">
          <use fill="#000" filter="url(#a)" />
          <use fill="#FFF" stroke="#C6C5DD" strokeOpacity=".499" />
        </g>
        <text fill="#C6C5DD" fontFamily="Lato-Regular, Lato" fontSize="14" transform="translate(6 4)">
          <tspan x="32" y="36">
            Label
          </tspan>
        </text>
      </g>
    </svg>
  );
}

// base color is stroke="#444",
export function LargeSearchGlassIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(0 .465)"
      >
        <path d="M15.147 14.56l-4.549-4.55" />
        <circle cx="6.542" cy="5.954" r="5.737" />
      </g>
    </svg>
  );
}

export function CheckIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        d="M3 7.75l3.5 3.5 6.5-6.5"
      />
    </svg>
  );
}

export function CoverSheetsIcon({ fill = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" {...props}>
      <g fill={fill} fillRule="nonzero">
        <path d="M10.625.088h3.333v12.667h-3.333zM3.292.088h3.333v12.667H3.292zM7.958.088h1.333v12.667H7.958zM.625.088h1.333v16H.625zM15.292.088h1.333v16h-1.333z" />
      </g>
    </svg>
  );
}

export function PrintIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <defs>
        <linearGradient id="a" x1="2.107%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#0026ca" />
          <stop offset="100%" stopColor="#0026ca" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="40" height="40" fill="url(#a)" rx="20" />
        <g stroke="#FFF" strokeLinecap="round" strokeLinejoin="round">
          <path d="M15.5 15.5v-3h9v3M15.5 24.5h-3v-4a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v4h-3" />
          <path d="M15.5 21.5h9v6h-9z" />
        </g>
      </g>
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <defs>
        <linearGradient id="a" x1="2.107%" y1="50%" y2="50%">
          <stop offset="0%" stopColor="#0026ca" />
          <stop offset="100%" stopColor="#0026ca" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <rect width="40" height="40" fill="url(#a)" rx="20" />
        <g stroke="#FFF" strokeLinecap="round" strokeLinejoin="round">
          <path d="M12.667 15.333h14.666M18 15.333v-2.666h4v2.666M24.667 18v8c0 .736-.597 1.333-1.334 1.333h-6.666A1.333 1.333 0 0 1 15.333 26v-8" />
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #0828CC
export function CursorIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path fill="none" fillRule="nonzero" stroke={stroke} strokeLinecap="square" d="M8.158 1.5v13" />
    </svg>
  );
}

// base color for tag is #000
export function DashBoardIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(0 1.263)"
      >
        <path d="M8.155.355v2.127M13.67 2.64l-1.503 1.504M15.956 8.155h-2.128M4.61 4.61l2.542 2.542M.355 8.155h2.127" />
        <circle cx="8.155" cy="8.155" r="1.418" />
        <path d="M14.172 13.12a7.8 7.8 0 1 0-12.034 0h12.034z" />
      </g>
    </svg>
  );
}

// base color for tag is #0828CC
export function TrashCanIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M14.19 7.001v9.74H3.06V7M.973 4.218h15.304M8.625 9.088v4.174M5.842 9.088v4.174M11.408 9.088v4.174M5.842 4.218V1.436h5.566v2.782" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function DirectoryIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(0 .671)"
      >
        <path d="M7.299 7.817l1.536 1.157c.35.263.556.675.556 1.113v.696H3.826v-.696c0-.438.206-.85.557-1.113l1.536-1.157" />
        <rect width="2.783" height="3.478" x="5.217" y="4.522" rx="1.391" />
        <path d="M.348.348h11.13c.769 0 1.392.623 1.392 1.391v12.522c0 .768-.623 1.391-1.392 1.391H.348V.348zM14.957 3.13v2.783" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function DocsIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M1.044 3.088h11.13v11.785H1.044z" />
        <path d="M3.13.47h11.827v13.094M3.826 6.362h5.565M3.826 8.98h5.565M3.826 11.6H6.61" />
      </g>
    </svg>
  );
}

// base color for tag is #C7C7CC
export function DownArrowIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M8 2.286v11.428M12 9.714l-4 4-4-4" />
      </g>
    </svg>
  );
}

// base color for tag is #C7C7CC
export function DownIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        d="M12 6l-4 4-4-4"
      />
    </svg>
  );
}

// base color for tag is #8E8E93
export function DownloadIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M16 14.214H0M.571 1.643H15.43L8 11.929z" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function ErrorsIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(1 .912)">
        <circle
          cx="8"
          cy="8"
          r="7.652"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
        />
        <g transform="translate(7 4)">
          <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35" d="M1 .522v4.174" />
          <circle cx="1" cy="7.478" r="1" fill="#000" fillRule="nonzero" />
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #C7C7CC
export function FilterIcon({ fill = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fill={fill}
        fillRule="nonzero"
        d="M11.6 5.2H4.4a.4.4 0 0 0-.32.64l3.6 4.8a.4.4 0 0 0 .64 0l3.6-4.8a.4.4 0 0 0-.32-.64z"
      />
    </svg>
  );
}

// base color for tag is #000
export function FollowUpsIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M15.2 1.293L4.444 10.436v5.333l2.581-3.276" />
        <path d="M.859 7.388L15.2 1.293l-2.868 15.238z" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function Import({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M9 8.216V1.26M14.565 1.955h2.087v9.74H1.348v-9.74h2.087M5.522 15.869h6.956M9 11.695v4.174" />
        <path d="M6.217 4.042L9 1.26l2.783 2.782" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function LedgersIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M1.739 1.02h13.913v15.303H1.739zM.348 8.671H3.13M.348 5.193H3.13M.348 12.15H3.13" />
        <path d="M6.609 6.584h5.565v4.174H6.609z" />
      </g>
    </svg>
  );
}

// base color for tag is #fff
export function LeftArrowIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M15.143 7.857H.857M5.857 12.857l-5-5 5-5" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function Loans({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(1 .912)"
      >
        <circle cx="8" cy="8" r="7.652" />
        <path d="M8 3.13v9.74M10.348 4.674c-1.128-.62-4.68-1.082-4.68 1.053 0 2.555 4.51 1.653 4.51 3.908 0 2.256-2.93 2.086-4.96 1.354" />
      </g>
    </svg>
  );
}

// base color for tag is #8E8E93
export function NoteIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M10.955 1.25v4.13h4.405" />
        <path d="M10.955 1.25h-9.91v15.485H15.36V5.38z" />
      </g>
    </svg>
  );
}

// base color for tag is #0828CC
export function PlusIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M8.125 1.421v13.334M14.792 8.088H1.458" />
      </g>
    </svg>
  );
}

// base color for tag is #FFF
export function Processing({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M1.25 9.082c0-4.05 3.313-7.364 7.363-7.364 2.872 0 5.376 1.62 6.554 4.05M15.977 9.082c0 4.05-3.313 7.364-7.364 7.364-2.872 0-5.376-1.62-6.554-4.05" />
        <path d="M15.83 1.497l-.589 4.345-4.418-.59M1.396 16.667l.59-4.345 4.418.59" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function ReportsIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M7.609 10.303h2.783v6.261H7.609zM1.348 13.086h2.783v3.478H1.348zM13.87 7.521h2.783v9.043H13.87zM2.74 7.52l3.477-3.478L9 6.825l5.565-5.565" />
        <path d="M11.087 1.26h3.478v3.478" />
      </g>
    </svg>
  );
}

// base color for tag is #C7C7CC
export function ReturnIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M1.357 11.571h12.857V.857" />
        <path d="M4.929 8l-3.572 3.571 3.572 3.572" />
      </g>
    </svg>
  );
}

// base color for tag is #E5E5EA
export function RightArrowIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M.774 7.57h14.285M10.06 2.57l5 5-5 5" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function RightCaretIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        d="M6 4l4 4-4 4"
      />
    </svg>
  );
}

// base color for tag is #C7C7CC
export function Search({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(1 1.088)"
      >
        <path d="M15.636 15.636l-4.072-4.072" />
        <circle cx="6.909" cy="6.909" r="6.545" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function SettingsIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(1 .912)"
      >
        <path d="M15.652 8a1.39 1.39 0 0 0-1.391-1.391h-.878a5.543 5.543 0 0 0-.593-1.432l.62-.62a1.391 1.391 0 1 0-1.967-1.968l-.62.62a5.543 5.543 0 0 0-1.432-.592v-.878a1.391 1.391 0 0 0-2.782 0v.878c-.51.132-.99.332-1.432.593l-.62-.62a1.391 1.391 0 1 0-1.968 1.967l.62.62c-.26.442-.46.922-.592 1.432h-.878a1.391 1.391 0 0 0 0 2.782h.878c.132.51.332.99.593 1.432l-.62.62a1.391 1.391 0 1 0 1.967 1.968l.62-.62c.442.26.922.46 1.432.592v.878a1.391 1.391 0 0 0 2.782 0v-.878c.51-.132.99-.332 1.432-.593l.62.62a1.391 1.391 0 1 0 1.968-1.967l-.62-.62c.26-.442.46-.922.592-1.432h.878c.768 0 1.391-.622 1.391-1.391z" />
        <circle cx="8" cy="8" r="2.087" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function ShippingIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M5.174 2.825l8.348 4.174M16.652 5.434L9.696 8.912 1.348 4.738M9.696 8.912v7.652" />
        <path d="M16.652 5.434v6.956l-6.956 4.174-8.348-4.174V4.738L8.304 1.26z" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function TasksIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(1 .912)"
      >
        <circle cx="1.905" cy="1.905" r="1.524" />
        <circle cx="1.905" cy="8" r="1.524" />
        <circle cx="1.905" cy="14.095" r="1.524" />
        <path d="M6.476 1.905h9.143M6.476 8h9.143M6.476 14.095h9.143" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function UnassociatedIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(.5)"
      >
        <path d="M.348.348h13.913v15.304H.348zM8 8.696l2.783 2.782" />
        <circle cx="6.261" cy="6.957" r="2.435" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function UnknownsIcon({ stroke = 'currentColor', fill = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(1 .912)">
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
          d="M15.652 15.652H.348V.348h5.565L8 3.13h7.652z"
        />
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
          d="M8 10.783v-.696c1.12 0 2.087-.968 2.087-2.087 0-1.12-.968-2.087-2.087-2.087-.83 0-1.63.621-1.942 1.336"
        />
        <circle cx="8" cy="12.87" r="1" fill={fill} fillRule="nonzero" />
      </g>
    </svg>
  );
}

// base color for tag is #C7C7CC
export function UpArrowIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M8 13.714V2.286M4 6.286l4-4 4 4" />
      </g>
    </svg>
  );
}

// base color for tag is #E5E5EA
export function XIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M11.733 4.267l-7.466 7.466M11.733 11.733L4.267 4.267" />
      </g>
    </svg>
  );
}

// base color for tag is #0828CC
export function CalendarIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M18.609 7.74v10.957H1.39V7.74" />
        <path d="M1.391 3.045h17.217v4.696H1.391zM10 1.48v3.13M5.304 1.48v3.13M14.696 1.48v3.13" />
      </g>
    </svg>
  );
}

// base color for tag is #000
export function BurgerMenuIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M.391 8.37H17.61M.391 2.891H17.61M.391 13.848H17.61" />
      </g>
    </svg>
  );
}

// base color for tag is #C7C7CC
export function XLargeIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M15.559 2.1L2.995 15.362M15.559 15.362L2.995 2.1" />
      </g>
    </svg>
  );
}

// base color for tag is #8E8E93
export function XLargeBoldIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M18.4 1.688l-16.8 16.8M18.4 18.488L1.6 1.688" />
      </g>
    </svg>
  );
}

// base color for tag is #0828CC
export function XMediumIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M10.667 1.455L1.333 11.72M10.667 11.721L1.333 1.455" />
      </g>
    </svg>
  );
}

// base color for tag is #0828CC
export function XMediumLargeIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.35">
        <path d="M11.652.69l-11 11M11.652 11.69l-11-11" />
      </g>
    </svg>
  );
}

// base color for tag is #0828CC
export function SearchIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(1 .778)"
      >
        <path d="M11.977 11.985L8.923 8.931" />
        <circle cx="5.432" cy="5.44" r="4.909" />
      </g>
    </svg>
  );
}

// base color for tag is #0828CC
export function MoreIcon({ fill = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill={fill} fillRule="nonzero" transform="translate(0 9.5)">
        <circle cx="12" cy="2.182" r="2.182" />
        <circle cx="2.182" cy="2.182" r="2.182" />
        <circle cx="21.818" cy="2.182" r="2.182" />
      </g>
    </svg>
  );
}

// base color for tag is #
export function NoResultsIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" fillRule="nonzero" d="M1.477 5.08H19.84v14.402H1.477zM21.253 5.08h65.79v14.402h-65.79z" />
        <g stroke="#0828CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path d="M57.366 82.433H.99V4.625h85.59v48.594M.99 20.186h85.59M20.443 4.625v15.561M86.02 81.99l-8.927-8.926" />
          <path
            fill="#E5E5EA"
            d="M66.89 77.208c7.924 0 14.348-6.424 14.348-14.347 0-7.924-6.424-14.347-14.348-14.347S52.543 54.937 52.543 62.86c0 7.923 6.423 14.347 14.347 14.347z"
          />
        </g>
        <g transform="translate(31.894 35.529)">
          <circle cx="3.259" cy="3.283" r="2.92" fill="#0828CC" fillRule="nonzero" />
          <circle cx="20.776" cy="3.283" r="2.92" fill="#0828CC" fillRule="nonzero" />
          <path stroke="#0828CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.232 13.988h15.57" />
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #
export function DragDropIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" fillRule="nonzero" d="M1.477 5.08H19.84v14.402H1.477zM21.253 5.08h65.79v14.402h-65.79z" />
        <g stroke="#0828CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path d="M57.366 82.433H.99V4.625h85.59v48.594M.99 20.186h85.59M20.443 4.625v15.561M86.02 81.99l-8.927-8.926" />
          <path
            fill="#E5E5EA"
            d="M66.89 77.208c7.924 0 14.348-6.424 14.348-14.347 0-7.924-6.424-14.347-14.348-14.347S52.543 54.937 52.543 62.86c0 7.923 6.423 14.347 14.347 14.347z"
          />
        </g>
        <g transform="translate(31.894 35.529)">
          <circle cx="3.259" cy="3.283" r="2.92" fill="#0828CC" fillRule="nonzero" />
          <circle cx="20.776" cy="3.283" r="2.92" fill="#0828CC" fillRule="nonzero" />
          <path stroke="#0828CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.232 13.988h15.57" />
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #
export function ErrorFaceIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="122" height="122" viewBox="0 0 122 122" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M83.073.607H6.376v120.522h109.567V33.477"
        />
        <path fill="#E5E5EA" d="M83.073 33.476h32.87L83.073.607z" />
        <path
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M115.943 33.476L83.073.606M83.073.607v32.87h32.87M50.203 82.674h21.913M33.768 92.64h54.784M33.768 102.606h54.784"
        />
        <g stroke="#0828CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path d="M49.558 68.972l22.154-5.538M68.943 48.64l8.307-8.307M77.25 48.64l-8.307-8.307" />
          <g>
            <path d="M44.02 48.64l8.307-8.307M52.327 48.64l-8.307-8.307" />
          </g>
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #
export function GotItFaceIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="122" height="122" viewBox="0 0 122 122" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M83.073.607H6.376v120.522h109.567V33.477"
        />
        <path
          fill="#E5E5EA"
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M83.073.607v32.87h32.87"
        />
        <path
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M115.943 33.476L83.073.606M50.203 82.674h21.913M33.768 92.64h54.784M33.768 102.606h54.784"
        />
        <g transform="translate(45.66 37.24)">
          <circle cx="4.442" cy="4.944" r="4.167" fill="#0828CC" fillRule="nonzero" />
          <path
            stroke="#0828CC"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8.609 17.446a8.335 8.335 0 0 0 16.67 0M22.5 3.554h8.335"
          />
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #fff
export function LockIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(16)"
      >
        <path d="M43.884.54c-15.17 0-27.09 11.92-27.09 27.09v16.254h54.18V27.63c0-15.17-11.92-27.09-27.09-27.09zM.54 43.884h86.688v75.852H.54z" />
        <circle cx="43.884" cy="76.392" r="10.836" />
        <path d="M43.884 87.228v10.836" />
      </g>
    </svg>
  );
}

// base color for tag is #fff
export function MailLargeIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="122" height="121" viewBox="0 0 122 121" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M120.177 1.536L31.363 72.587v41.447l21.315-25.46" />
        <path d="M1.759 48.904L120.177 1.536 96.493 119.954z" />
      </g>
    </svg>
  );
}

// base color for tag is #
export function NoErrorsIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="125" height="124" viewBox="0 0 125 124" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(1.625 1.54)">
        <path
          fill="#F7F7F9"
          stroke="#C7C7CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M110.366 120.837h-99.33C4.943 120.837 0 115.684 0 109.328V0h44.146l16.555 23.016h60.702v86.312c0 6.356-4.942 11.509-11.037 11.509z"
        />
        <path stroke="#C7C7CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M60.701 43.156V77.68" />
        <ellipse
          cx="60.701"
          cy="97.82"
          stroke="#C7C7CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          rx="2.759"
          ry="2.877"
        />
        <ellipse cx="60.701" cy="97.82" fill="#C7C7CC" fillRule="nonzero" rx="2.759" ry="2.877" />
      </g>
    </svg>
  );
}

// base color for tag is #
export function NoResultsLargeIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="122" height="122" viewBox="0 0 122 122" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#F7F7F9" fillRule="nonzero" d="M1.396 6.509H27.12v20.174H1.396zM29.099 6.509h92.16v20.174H29.1z" />
        <g stroke="#0828CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path d="M80.676 116.281H.41V5.501h121.86v69.187M.41 27.656h121.86M28.105 5.5v22.156M121.473 115.651l-12.71-12.71" />
          <path
            fill="#E5E5EA"
            d="M94.236 108.842c11.282 0 20.428-9.146 20.428-20.428 0-11.281-9.146-20.426-20.428-20.426-11.281 0-20.427 9.145-20.427 20.426 0 11.282 9.146 20.428 20.427 20.428z"
          />
        </g>
        <g transform="translate(44.41 49.5)">
          <circle cx="4.639" cy="4.674" r="4.157" fill="#0828CC" fillRule="nonzero" />
          <circle cx="29.58" cy="4.674" r="4.157" fill="#0828CC" fillRule="nonzero" />
          <path stroke="#0828CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6.025 19.916h22.17" />
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #
export function SuccessFaceIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="122"
      height="122"
      viewBox="0 0 122 122"
      {...props}
    >
      <defs>
        <path id="a" d="M.678.266c0 9.554 7.746 17.3 17.3 17.3S35.279 9.82 35.279.266H.678z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M83.053.49H6.356V121.01h109.567V33.36"
        />
        <path fill="#E5E5EA" d="M83.053 33.359h32.87L83.053.489z" />
        <path
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M115.924 33.359L83.054.489M83.053.49v32.87h32.87M50.183 82.557h21.913M33.748 92.522h54.784M33.748 102.488h54.784"
        />
        <g transform="translate(43 54.17)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path fill="#C7C7CC" d="M-4.322 22.565h44.601v-27.3H-4.322z" mask="url(#b)" />
        </g>
        <g stroke="#0828CC" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path d="M78.279 54.436c0 9.555-7.746 17.3-17.301 17.3-9.554 0-17.3-7.745-17.3-17.3h34.6zM78.279 42.902a5.767 5.767 0 0 0-11.534 0M55.212 42.902a5.767 5.767 0 0 0-11.533 0" />
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #
export function ClientIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#0828CC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M21 10.13L10.5.795 0 10.129v15.167h12.833" />
        <g transform="translate(14 13.046)">
          <circle cx="6.748" cy="6.748" r="6.455" fill="#C7C7CC" />
          <path d="M6.748 2.64v8.215M8.728 3.943c-.95-.523-3.947-.913-3.947.887 0 2.156 3.805 1.395 3.805 3.297 0 1.903-2.473 1.76-4.185 1.142" />
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #
export function InvestorIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(1 .778)">
        <path
          fill="#C7C7CC"
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
          d="M14 .609L.609 7.913v3.652H27.39V7.913z"
        />
        <path
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
          d="M3.043 22.522v-7.305M10.348 22.522v-7.305M17.652 22.522v-7.305M24.957 22.522v-7.305M.609 26.174H27.39"
        />
        <circle cx="14" cy="6.696" r="1.217" fill="#0828CC" fillRule="nonzero" />
      </g>
    </svg>
  );
}

// base color for tag is #8E8E93
export function PLusLargeIcon({ stroke = 'currentColor', ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" {...props}>
      <g fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M15 1.778v28M29 15.778H1" />
      </g>
    </svg>
  );
}

// base color for tag is #
export function TitleCompanyIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#0828CC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path
          fill="#C7C7CC"
          d="M7.913 22.522v1.826c0 2.739-3.043 3.043-3.043 3.043h18.26a3.043 3.043 0 0 0 3.044-3.043v-1.826H7.913z"
        />
        <path d="M22.522 18.87V.609H1.826v23.739A3.043 3.043 0 0 0 4.87 27.39h1.826M7.913 6.696h8.522M7.913 11.565h8.522M7.913 16.435h8.522" />
      </g>
    </svg>
  );
}

// base color for tag is #
export function ClientLargeIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#0828CC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M36.684 18.184l-18-16-18 16v26h22" />
        <circle cx="36.251" cy="34.751" r="11.065" fill="#C7C7CC" />
        <path d="M36.251 26.89v14.083M39.646 29.123c-1.63-.896-6.766-1.565-6.766 1.522 0 3.696 6.522 2.39 6.522 5.652 0 3.261-4.239 3.016-7.174 1.957" />
      </g>
    </svg>
  );
}

// base color for tag is #
export function InvestorLargeIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="46" viewBox="0 0 48 46" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(0 -.222)">
        <path
          fill="#C7C7CC"
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
          d="M24 1.043L1.043 13.565v6.261h45.914v-6.26z"
        />
        <path
          stroke="#0828CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.35"
          d="M5.217 38.609V26.087M17.74 38.609V26.087M30.26 38.609V26.087M42.783 38.609V26.087M1.043 44.87h45.914"
        />
        <circle cx="24" cy="11.478" r="2.087" fill="#0828CC" fillRule="nonzero" />
      </g>
    </svg>
  );
}

// base color for tag is #
export function TitleCompanyLargeIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#0828CC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path
          fill="#C7C7CC"
          d="M7.913 22.522v1.826c0 2.739-3.043 3.043-3.043 3.043h18.26a3.043 3.043 0 0 0 3.044-3.043v-1.826H7.913z"
        />
        <path d="M22.522 18.87V.609H1.826v23.739A3.043 3.043 0 0 0 4.87 27.39h1.826M7.913 6.696h8.522M7.913 11.565h8.522M7.913 16.435h8.522" />
      </g>
    </svg>
  );
}

export function SearchGlass({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        transform="translate(1 .778)"
      >
        <path d="M11.977 11.985L8.923 8.931" />
        <circle cx="5.432" cy="5.44" r="4.909" />
      </g>
    </svg>
  );
}

export function NoResultsManDeskIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="459" height="384" viewBox="0 0 459 384">
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M103.75 205.57h93v176h-93zM289.75 205.57h93v176h-93z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M298.43 205.58H103.07v176h10v-167h176.36v167h9zM250.25 189.58h-101l-10-96h101z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M177.75 205.58h23l7-37h-23z"
        />
        <path
          fill="#E0E0E0"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M382.75 205.58h-84l18.8-103.42a8 8 0 0 1 7.89-6.58h69.47a6.55 6.55 0 0 1 6.44 7.72l-18.6 102.28z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M246.57 64.26a39.29 39.29 0 0 1-14.82-11.91c-6.34-8.44-5.67-10.09-5.67-10.09s.5-1 2-.2l-2.41-3.38s3.07-2 5.35 1.52c0 0 1.87.11 4.74 3.26s8.47 7.89 11.94 7.1c3.47-.79 1.19-8.72 4.65-9.58 3.46-.86 1.31 3.34 1.54 6.49.23 3.15 3.37 9 2.54 10.5-.83 1.5-1 5.42-1 5.42l-8.86.87zM435.66 67.03a39.29 39.29 0 0 0 14.82-11.91c6.33-8.44 5.66-10.09 5.66-10.09s-.5-1-2-.2l2.41-3.38s-3.08-2-5.36 1.52c0 0-1.86.11-4.74 3.26s-8.47 7.89-11.94 7.1c-3.47-.79-1.18-8.72-4.65-9.58-3.47-.86-1.3 3.34-1.54 6.49-.24 3.15-3.37 9-2.53 10.5.84 1.5 1 5.42 1 5.42l8.87.87z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M437.47 381.57h5.28M9.05 381.57h424.04M0 381.57h4.63M112.75 214.57h177v167h-177z"
        />
        <ellipse
          cx="192.185"
          cy="138.94"
          fill="#E0E0E0"
          fillRule="nonzero"
          rx="5.5"
          ry="8"
          transform="rotate(-6.87 192.185 138.94)"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M438.65 67.51c-7-10.67-15-6.88-15-6.88l-12 51.58-88.77 11.46-47.93-24.81-17-38.15s-12.63-4.11-14.67 7.14l16 45.89 49.4 36.39-9.93 55.45h74L383 150.13l45.38-21.55 10.27-61.07z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M365.33 83.99c1.16 1.91-7.07 6.55-7.38 29.35-.2 15.23-30.2 23.23-30.2 23.23l.81-18.5s-12.33 2.17-14.75-9 .22-43 .22-43 39.43-1.55 51.3 17.92z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M365.44 82.99c0-11.85-2.36-23.42-16.05-27-7.8-2-15.58 1.56-26.39 2a79.87 79.87 0 0 1-14-.59s-2 3.24 1.08 8.18c2.74 4.34 8.53 4.33 8.53 4.33a17.63 17.63 0 0 1 10.21 7.87c5.06 8.63 17 10 17 10l-.79 6.95 3 .34s.56-9 6.69-7.79c6.13 1.21-3.84 16.68 2.44 19.66-.07.02 8.23-5.84 8.28-23.95z"
        />
        <path
          fill="#E0E0E0"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M335.58 133.1l-5.71 10.29-5.37 62.19h-18.76l19.01-61 3.15-11.48z"
        />
        <ellipse
          cx="327.82"
          cy="89.01"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          rx="4.57"
          ry="6.06"
        />
        <ellipse
          cx="313.29"
          cy="89.01"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          rx="4.57"
          ry="6.06"
        />
        <path
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M323.25 88.22c-2.5-2.86-5.43 0-5.43 0l-1 10.6h3.82"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M332.39 89.01h13.11"
        />
        <path
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M328.36 117.99a19.64 19.64 0 0 0 5.61-1.33M324.5 163.27h6.66"
        />
        <circle
          cx="414.21"
          cy="2.71"
          r="2.71"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <circle
          cx="308.32"
          cy="27.11"
          r="2.54"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          opacity=".4"
        />
        <circle
          cx="124.29"
          cy="56.37"
          r="2.54"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          opacity=".4"
        />
        <circle
          cx="207.26"
          cy="5.24"
          r="2.54"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M43 381.58h42.5l5-61.31H38z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M36.33 320.27h55.83v8.37H36.33zM89.82 328.64H38.68M64.25 328.64v52.93M51.33 334.32l2.09 47.25M79.42 328.64l-5 52.93"
        />
        <path
          fill="#E0E0E0"
          fillRule="nonzero"
          d="M77.24 312.96h-.11l-9.05-6.05 1.42 6.21 2.52 4.44 4.34-3.17a.49.49 0 0 1 .68.09l4.65 5.84h5.16L84 315.48l-6.76-2.52z"
        />
        <path
          stroke="#757575"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M40.71 320.27l7.66-7.69 5.67 1.66 13.33-9 10.1 6.75 6.9 2.59 3.42 5.69z"
        />
        <path
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M326.61 108.02c2.63-.67 6.12-2.14 7.93-5.32M359.22 156.32h1.87M345.5 156.32h10.47"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M235.13 104.25l9.31 85.32M233.97 93.57l.67 6.18"
        />
        <path
          fill="#E0E0E0"
          fillRule="nonzero"
          d="M364.38 83.07c0-8.28-1-18.4-9.33-23.54a117.68 117.68 0 0 0-15.89 2.79c-3.44.77-7 1.56-10.54 2.16a29.38 29.38 0 0 1-19.24-2.9 10.13 10.13 0 0 0 1.46 3.58c2.4 3.81 7.63 3.87 7.68 3.87h.3a18.72 18.72 0 0 1 10.78 8.32c4.74 8.11 16.11 9.49 16.23 9.5l1 .12-.79 7 1 .11c.26-1.69 1-5 3.25-6.7a5.12 5.12 0 0 1 3.23-1.09c.433 0 .865.044 1.29.13a3.25 3.25 0 0 1 2.19 1.41c1.27 1.92.56 5.2-.19 8.68-.82 3.84-1.67 7.8.16 9.31 1.69-1.61 7.37-8.01 7.41-22.75zM113.47 215.59h175.25v10.25H113.47z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          stroke="#757575"
          strokeLinecap="round"
          strokeWidth="2"
          d="M166.59 331.95l-2.71 3.16M239.78 246.41l-68.87 80.49M192.18 318.26l-3.2 3.74M234.04 269.35l-38.4 44.87"
        />
        <path stroke="#757575" strokeLinecap="round" strokeWidth="2" d="M298.75 214.57h84" />
      </g>
    </svg>
  );
}

export function SignIcon({ signName, positionText, ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="rotate(-5 85.808 -181.038)">
          <path
            stroke="#757575"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M.578 33.157L21.301 8.289M58.602 33.157L37.88 8.289"
          />
          <circle cx="31.183" cy="10.008" r="6.5" fill="#E0E0E0" />
          <circle
            cx="29.59"
            cy="8.289"
            r="8.289"
            stroke="#757575"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            fill="#757575"
            fillRule="nonzero"
            d="M11.15 68.169v-14.6h1.9v6.32h7.4v-6.32h1.9v14.6h-1.9v-6.4h-7.4v6.4h-1.9zm14.44 0v-14.6h10.96v1.86h-9.06v4.48h5.2v1.86h-5.2v4.54h9.06v1.86H25.59zm17.54 0v-5l-5.18-9.6h2.1l4.04 7.5 4.06-7.5h2.04l-5.16 9.58v5.02h-1.9z"
          />
        </g>
        <g stroke="#757575" strokeLinecap="round" strokeWidth="2">
          <path d="M97.748 83.823l.336 3.835c.139 1.588-1.394 3.02-3.424 3.198l-82.536 7.22c-1.353.119-2.525-.644-2.618-1.702L5.665 52.466M97.335 79.534l-3.758-42.96c-.139-1.583-1.896-2.721-3.926-2.543L7.115 41.25c-1.353.119-2.375 1.07-2.283 2.124l.386 4.411" />
        </g>
      </g>
    </svg>
  );
}

export function ManAtDesk() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="646" height="538" viewBox="0 0 323 270">
      <g fill="none" fillRule="evenodd" transform="translate(0 1)">
        <path fill="#3B3860" d="M76 140h68v128H76z" />
        <path fill="#797695" d="M76 140h203v7H76z" />
        <path fill="#3B3860" d="M203.781 253a6.972 6.972 0 0 1-3.23 4.56L183 268h34v-15h-13.219z" />
        <path fill="#3B3860" d="M211 140h68v128h-68z" />
        <path
          fill="#797695"
          d="M76 140h7v128h-7zM211 140h7v128h-7zM181.15 128h-69.086a3.242 3.242 0 0 1-3.234-2.917l-6.82-65.07a1.824 1.824 0 0 1 .469-1.414c.351-.384.85-.602 1.371-.599h69.086a3.242 3.242 0 0 1 3.234 2.917l6.82 65.07a1.824 1.824 0 0 1-.469 1.414c-.351.384-.85.602-1.371.599z"
        />
        <path
          fill="#2A2547"
          d="M211 161h-34.084c-8.238 0-14.916 6.663-14.916 14.882V253h20.245l5.096-65.853L211 161z"
        />
        <path
          fill="#3B3860"
          d="M130 140h17.147l4.824-24.942a1.717 1.717 0 0 0-.382-1.428 1.797 1.797 0 0 0-1.363-.63h-12.562c-1.416.012-2.628.997-2.9 2.357L130 140zM212 161l-13.646.05c-4.696.018-9.128 2.138-12.038 5.76a14.938 14.938 0 0 0-2.909 12.84L200.912 253H212v-92zM180.929 128a2.071 2.071 0 0 0 1.54-.68 2.05 2.05 0 0 0 .52-1.596l-6.795-64.923A3.139 3.139 0 0 0 173.07 58a2.071 2.071 0 0 0-1.54.68 2.05 2.05 0 0 0-.52 1.596l6.795 64.923A3.139 3.139 0 0 0 180.93 128z"
        />
        <path stroke="#3B3860" d="M0 268.5h323" />
        <path
          fill="#CFCEDD"
          d="M200.356 126L204 140h-52.962s-.605-2.579 3.039-6.263c3.644-3.684 17.127-1.105 17.127-1.105L200.356 126z"
        />
        <path fill="#3B3860" d="M82 146h129v28H82z" opacity=".4" style={{ mixBlendMode: 'multiply' }} />
        <ellipse
          cx="139.627"
          cy="89.429"
          fill="#F9F9FC"
          opacity=".4"
          rx="4.01"
          ry="5.832"
          transform="rotate(-6.87 139.627 89.429)"
        />
        <path
          fill="#797695"
          d="M279.342 140H218l13.729-75.215A5.836 5.836 0 0 1 237.49 60h50.73a4.791 4.791 0 0 1 3.667 1.71 4.75 4.75 0 0 1 1.036 3.897L279.342 140z"
        />
        <path
          fill="#3B3860"
          d="M273 140h5.89l14.06-76.407a3.02 3.02 0 0 0-.661-2.492A3.1 3.1 0 0 0 289.932 60a2.612 2.612 0 0 0-2.584 2.13L273 140z"
        />
        <path fill="#FFF" d="M199.862 133h4.276c3.79 0 6.862 3.134 6.862 7h-18c0-3.866 3.072-7 6.862-7z" />
        <path
          fill="#FFF"
          d="M278.808 94.955c1.453 13.184-5.81 45.045-5.81 45.045H204s6.9-51.483 33.41-60.888a12.494 12.494 0 0 1 2.106-.644c36.387-5.853 38.253 7.06 39.292 16.487z"
        />
        <path
          fill="#CFCEDD"
          d="M265.92 50.972c.836 1.381-5.103 4.737-5.32 21.23C260.45 83.215 238.798 89 238.798 89l.584-13.377s-8.898 1.569-10.645-6.508c-1.746-8.077.159-31.093.159-31.093s28.457-1.129 37.023 12.95z"
        />
        <path
          fill="#3B3860"
          d="M267 50.323c0-8.731-1.738-17.242-11.782-19.877-5.75-1.473-11.48 1.148-19.44 1.472a58.822 58.822 0 0 1-10.308-.434s-1.473 2.385.795 6.015c2.018 3.202 6.281 3.187 6.281 3.187a12.96 12.96 0 0 1 7.437 5.816c3.726 6.354 12.518 7.363 12.518 7.363l-.581 5.153 2.209.25s.412-6.625 4.926-5.742-2.828 12.28 1.797 14.474c0-.037 6.111-4.344 6.148-17.677z"
        />
        <path fill="#FFF" d="M228 85l-24.821 37.606-7.179 4.429L199.683 140h4.095z" />
        <path
          fill="#797695"
          d="M245 87.901l-4.135 6.967-3.722 34.719L226 132l11.143-36.256 2.285-8.439c1.749-.941 5.572.596 5.572.596z"
        />
        <path
          fill="#CFCEDD"
          d="M249.356 126L253 140h-52.962s-.605-2.579 3.039-6.263c3.644-3.684 17.127-1.105 17.127-1.105L249.356 126z"
        />
        <path stroke="#CFCEDD" d="M249 126l8.563-1.65L259 120" />
        <ellipse cx="239.5" cy="54.5" stroke="#3B3860" rx="3.5" ry="4.5" />
        <ellipse cx="228.5" cy="54.5" stroke="#3B3860" rx="3.5" ry="4.5" />
        <path stroke="#3B3860" d="M236 53.963c-1.962-2.167-4.262 0-4.262 0L231 62h2.99M242 54.5h10" />
        <path stroke="#797695" d="M240 77a13.62 13.62 0 0 0 4-1" />
        <path stroke="#CFCEDD" d="M203 123l5 2" />
        <path stroke="#3B3860" d="M237 109.5h5" />
        <path fill="#3B3860" d="M164.781 253a6.972 6.972 0 0 1-3.23 4.56L144 268h34v-15h-13.219z" />
        <path fill="#797695" d="M182.342 254l.658-9h-21v9zM211 254l-.044-9H198l1.957 9z" />
        <path
          fill="#CFCEDD"
          d="M113 34H88.934L88 25h24.066zM144 34h-24.059L119 25h24.059zM176 34h-24.059L151 25h24.059zM207 34h-24.066L182 25h24.066z"
        />
        <circle cx="93.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="98.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="103.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="108.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="124.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="129.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="134.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="139.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="156.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="161.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="166.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="170.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="187.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="192.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="197.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <circle cx="201.5" cy="29.5" r="1.5" fill="#797695" opacity=".5" />
        <ellipse cx="74.624" cy="29.617" fill="#CFCEDD" rx="7.743" ry="8.828" transform="rotate(-6.87 74.624 29.617)" />
        <path stroke="#3B3860" strokeLinecap="round" strokeWidth="2" d="M71 30.166L73.966 33 79 25" />
        <path fill="#CFCEDD" d="M214 79h-28.033L183 51h28.033zM94 115H65.96L63 87h28.04z" />
        <path stroke="#797695" d="M188 56.5h19" opacity=".5" />
        <path stroke="#3B3860" d="M190 62.5h9M68 93.5h18" />
        <path stroke="#797695" d="M69 99.5h18M70 106.5h8" opacity=".5" />
        <circle cx="295" cy="142" r="2" stroke="#3B3860" />
        <circle cx="223" cy="16" r="2" stroke="#CFCEDD" />
        <circle cx="139" cy="2" r="2" stroke="#CFCEDD" />
        <circle cx="77.5" cy="65.5" r="1.5" stroke="#3B3860" />
        <path stroke="#CFCEDD" d="M213 43H59.912L57 17h153.088z" />
        <path stroke="#3B3860" strokeLinecap="round" d="M205 16h5.386l.614 6M60 37l.638 6H67" />
        <path fill="#3B3860" d="M31.619 268h30.762L66 223H28z" />
        <path fill="#3B3860" d="M27 224h41v6H27z" />
        <path
          stroke="#2A2547"
          d="M66 229.5H29M47.5 229v39M37 229l2 39M58 229l-4 39M218 146.5h61M279 139.5h-6M211 268V146H82"
        />
        <path fill="#CFCEDD" d="M30 224l5.54-5.632 4.087 1.223L49.26 213l6.738 6.591 5.54.242L64 224z" />
        <path stroke="#797695" d="M49 213l1.204 5.5 2.011 3.654 3.393-2.563L59 224" opacity=".5" />
      </g>
    </svg>
  );
}

export function MadisonColumnIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M10.17 11.805h18.19v-1.694H10.17zM14.183 30.891h1.694v-12.31h-1.694zM18.418 30.891h1.694v-12.31h-1.694zM22.653 30.891h1.694v-12.31h-1.694zM26.216 16.04H12.314a.847.847 0 1 1 0-1.694h13.902a.847.847 0 1 1 0 1.694"
        />
      </g>
    </svg>
  );
}

export function VerifiedWaterMarkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="272" height="272" viewBox="0 0 272 272">
      <g fill="none" fillRule="evenodd" opacity=".15">
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M270 136c0 11.585-10.607 21.62-13.463 32.305-2.955 11.054 1.126 25.032-4.474 34.71-5.68 9.818-19.88 13.238-27.847 21.205-7.967 7.966-11.387 22.166-21.204 27.845-9.677 5.6-23.655 1.518-34.708 4.472C157.62 259.393 147.584 270 136 270c-11.585 0-21.62-10.607-32.305-13.463-11.054-2.955-25.032 1.126-34.71-4.474-9.818-5.68-13.238-19.88-21.205-27.847-7.966-7.967-22.166-11.387-27.845-21.204-5.6-9.677-1.517-23.655-4.472-34.708C12.607 157.62 2 147.584 2 136c0-11.585 10.607-21.62 13.463-32.305 2.955-11.054-1.126-25.032 4.474-34.71 5.68-9.818 19.88-13.238 27.847-21.205 7.967-7.966 11.387-22.166 21.204-27.845 9.677-5.6 23.655-1.517 34.708-4.472C114.38 12.607 124.416 2 136 2c11.585 0 21.62 10.607 32.305 13.463 11.054 2.955 25.032-1.126 34.71 4.474 9.818 5.68 13.238 19.88 21.205 27.847 7.966 7.967 22.166 11.387 27.845 21.204 5.6 9.677 1.518 23.655 4.472 34.708C259.393 114.38 270 124.416 270 136zm-221 1c0-48.601 39.399-88 88-88m88 88c0 48.601-39.399 88-88 88"
        />
        <text
          fill="#000"
          fontFamily="Lato-Bold, Lato"
          fontSize="41"
          fontWeight="bold"
          transform="rotate(-49 137.8 132.38)"
        >
          <tspan x="43.105" y="149.074">
            VERIFIED
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export function ProjectVerifyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
      <g fill="none" fillRule="nonzero">
        <path fill="#304FFE" d="M0 0h55.725v55.725H0z" />
        <path
          fill="#FFF"
          d="M9.167 12.622h6.085L6.046 41.097H0V22.153l3.082 9.505 6.085-19.036zm9.518 28.475V12.622h22.312v5.929H24.692v5.266h8.972v5.93h-8.972v5.421h16.305v5.93H18.685z"
        />
      </g>
    </svg>
  );
}
