/** @jsx jsx */
import {jsx} from '@emotion/core';
import React, {useState, useCallback, useContext} from 'react';
import {adalApiPost} from '../AdalConfig';
import Layout from '../Layout/Layout';
import Button from '../ui/Button';
import {useDropzone} from 'react-dropzone'
import {AuthContext} from '../AuthContext';
import {Redirect} from 'react-router-dom';

const innerWrap = {
  maxWidth: 1144,


  position: 'relative',
  zIndex: 10,
  '@media(min-width: 768px)': {
    margin: '152px auto',
  },
  '@media print': {marginTop: 0, padding: 0},
};

const container = {
  display: 'flex',
  flexDirection: 'column',
  height: '50%',
  width: '50%',
  margin: 'auto',
  marginTop: -100,
  minHeight: 400
};

const dropZone = {
  width: '100%',
  height: 100,
  border: '2px solid #424242',
  borderRadius: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const passwordField = {
  width: '100%',
  marginTop: 20,
  paddingLeft: 10,
  border: '2px solid #424242',
  borderRadius: 2,
  height: 35,
}

const bankSelectWrap = {
  height: 30,
  marginTop: 20
}

const buttonWrap = {
  marginTop: 20
}

const uploadResultsWrap = {
  h3: {
    margin: 10
  }
}

const errorWrap = {
  color: '#cc0000',
  marginTop: 20
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const UploadResults = ({counts}) => {
  const {duplicateCount, successfulCount, failureRowsCount} = counts;
  return (
    <div css={uploadResultsWrap}>
      <h3>{successfulCount} accounts were added successfully.</h3>
      <h3>{duplicateCount} accounts were duplicates and skipped.</h3>
      {!!failureRowsCount && <h3>{failureRowsCount} row(s) were invalid. See downloaded failures file.</h3>}
    </div>
  )
}

const BulkUploader = () => {
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState('');
  const [uploadResult, setUploadResult] = useState(null);
  const [error, setError] = useState('');
  const { user } = useContext(AuthContext);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  const onSubmitClick = async () => {
    if (!file || !password) {
      setError('Please select a file and enter a password.');
      return;
    }
    setError('');
    let base64 = await toBase64(file);
    base64 = base64.substr(base64.indexOf(',') + 1);
    const result = await adalApiPost('/api/accounts/bulkupload', {base64file: base64, password});
    if(result.error) {
      setError(result.error);
      return;
    }
    setUploadResult(result);
    if (result.failureExcelFile) {
      const a = document.createElement("a");
      a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + result.failureExcelFile;
      a.download = "Failures.xlsx";
      a.click();
    }

  }

  if(!user.roles.includes('Admin')) {
    return <Redirect to='/' />;
  }
  return (
    <Layout>
      <div css={innerWrap}>
        <div css={container}>
          <div css={dropZone} {...getRootProps()}>
            <input {...getInputProps()} />
            {
              file ?
                <p>{file.name}</p> :
                <p>Click here to select an Accounts file</p>
            }
          </div>
          <input css={passwordField}
                 type="password"
                 onChange={e => setPassword(e.target.value)}
                 placeholder="Accounts File Password"/>
          <select css={bankSelectWrap}>
            <option>BankUnited</option>
          </select>
          <Button css={buttonWrap} onClick={onSubmitClick}>Submit</Button>
          {error && <h3 css={errorWrap}>{error}</h3>}
          {uploadResult && <div>
            <UploadResults counts={uploadResult}/>
          </div>}
        </div>
      </div>
    </Layout>
  )
}

export default BulkUploader;
