import React, { useState } from 'react'
import { TrashCanIcon } from '../../ui/icons'
import TextInput from '../../ui/Inputs/InputNumber'

export default function FurtherCredit(props ) {

    const {value, getAccount, onChange, routingNumber, furtherCreditError , deleteFurtherCredit, idx} = props
    const checkIfAccountExists = async (value) => {
        const prevAccount = await getAccount(null, value)
         const accountExists = Object.entries(prevAccount).length !== 0;
          onChange(idx, accountExists && prevAccount.some(account => account.routingNumber.toString() === routingNumber) ||
        false, "isDuplicate")
    }
  return (
    <div style={{ position: 'relative' }}>
        <div style={{ position: 'relative', display: 'inline-block' }}>
        <TextInput
                  style={{position: 'relative'}}
                  labelOverrides={{ marginTop: 15 }}
                  label="Further Credit"
                  value={value.furtherCredit}
                  onChange={(e) => onChange(idx, e.target.value, "furtherCredit") }
                  error={ furtherCreditError ? "Required": value.isDuplicate ? `Further credit already exists` : '' }
                  onBlur={() => {
                    checkIfAccountExists(value.furtherCredit) 
                  }}
                 onKeyUp={() => onChange(idx, false, "isDuplicate")}
                />
              { idx > 0 &&<TrashCanIcon style={{position: 'absolute', right: 16, top: 64}}  onClick={deleteFurtherCredit}/>}
        </div>
     </div>
  )
}
