/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import colors from '../../styles/colors';

const baseStyle = {
  backgroundColor: colors.grayExLight,
  borderRadius: 3,
  display: 'inline-block',
  maxWidth: 536,
  width: '49%',
  padding: '24px 32px',
  verticalAlign: 'top',
};
const activeStyle = {
  boxShadow: `0 0 0 1.4px ${colors.bluePrimary}`,
  border: `1px solid ${colors.white}`,
};

export default function Panel({ active, children, styleOverrides }) {
  return <div css={[baseStyle, active && activeStyle, styleOverrides]}>{children}</div>;
}

export function PanelHeader({ text, largeText, iconComponent, ...props }) {
  const textStyles = {
    fontSize: largeText ? 20 : 16,
    fontWeight: 'bold',
  };
  return (
    <div>
      {iconComponent && <div css={{ marginRight: 16 }}>{iconComponent}</div>}
      <div css={textStyles} {...props}>
        {text}
      </div>
    </div>
  );
}
