import React, { Component } from 'react';
import Select, { Async, components, createFilter } from 'react-select';
import escapeRegExp from 'lodash/escapeRegExp';

import { containerStyles, inputStyles, openMenuStyles, itemStyles, searchglassStyles, matchedStyles } from './css';
import { SearchGlass, XIcon } from '../icons';

const customStyles = {
  control: (styles, { selectProps: { isError } }) => ({
    styles,
    ...inputStyles(isError),
  }),
  container: (styles, { isFocused }) => ({
    ...styles,
    ...containerStyles(isFocused),
  }),
  menu: () => ({
    // This removes original library style
  }),
  menuList: () => openMenuStyles,
  option: (styles, { isFocused }) => ({ ...styles, ...itemStyles(isFocused) }),
};

const Option = ({ children, ...props }) =>
  children.subLabel ? (
    <components.Option {...props}>
      <span css={{ fontWeight: 700 }}>{children.label}</span>
      <br />
      {children.subLabel}
    </components.Option>
  ) : (
    <components.Option {...props}>{children.label}</components.Option>
  );

const SingleValue = ({ data, ...props }) => <components.SingleValue {...props}>{data.label}</components.SingleValue>;

const DropdownIndicator = ({ selectProps, isFocused, ...props }) => {
  const { inputValue } = selectProps;
  return !inputValue ? (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SearchGlass className={searchglassStyles(isFocused)} />
      </components.DropdownIndicator>
    )
  ) : (
    <components.ClearIndicator {...props}>
      <XIcon className={searchglassStyles(inputValue)} />
    </components.ClearIndicator>
  );
};

const formatOptionLabel = (option, { inputValue }, filter, match) => {
  const { label, ...optionRest } = option;
  const { matchFrom } = filter;
  // bolds letters that match search filter.
  // possible options are start of word, all matches, or custom filter
  const splitString = text => {
    if (matchFrom === 'start') {
      return text.split(new RegExp(`(^${escapeRegExp(inputValue)})`, 'i'));
    }
    if (matchFrom === 'all') {
      return text.split(new RegExp(`(${escapeRegExp(inputValue)})`, 'i'));
    }
    return [text];
  };
  const toBold = text =>
    (match && match(text, inputValue)) ||
    ((matchFrom === 'start' || matchFrom === 'all') && text.toLowerCase() === inputValue.toLowerCase());
  const parts = splitString(label).map((p, i) =>
    toBold(p) ? (
      <span key={`${p + i}`} className={matchedStyles}>
        {p}
      </span>
    ) : (
      p
    ),
  );
  return {
    label: <span>{parts}</span>,
    ...{ ...optionRest },
  };
};

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: false,
  matchFrom: 'start',
};

export default class SearchSelect extends Component {
  state = {
    inputText: '',
  };

  selectProps = (inputText, props) => ({
    components: {
      DropdownIndicator,
      IndicatorSeparator: null,
      Option: props.Option || Option,
      SingleValue: props.SingleValue || SingleValue,
    },
    formatOptionLabel: (...args) => formatOptionLabel(...args, props.filterConfig || filterConfig, props.match),
    styles: customStyles,
    onInputChange: inputValue => {
      this.setState({
        inputText: inputValue,
      });
    },
    // menuIsOpen: inputText !== '',
    inputValue: inputText,
    onSelectResetsInput: false,
    backspaceRemoves: false,
    noOptionsMessage: () => null,
    filterOption: createFilter(props.filterConfig || filterConfig),
    ...props,
  });

  render() {
    const { inputText } = this.state;
    const { isAsync, ...props } = this.props;
    return isAsync ? (
      <Async cacheOptions isClearable loadOptions={props.loadOptions} {...this.selectProps(inputText, props)} />
    ) : (
      <Select isClearable {...this.selectProps(inputText, props)} />
    );
  }
}
