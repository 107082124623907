/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';

import { NoResultsManDeskIcon } from '../ui/icons';

const noResultsStyle = {
  fontSize: 24,
  textAlign: 'center',
  marginTop: 74,
};

export default function NoResultsPanel({ searchString }) {
  return (
    <Fragment>
      <div css={noResultsStyle}>
        No results matching<span>"{searchString}"</span>
      </div>
      <div css={{ textAlign: 'center', margin: '72px auto' }}>
        <NoResultsManDeskIcon />
      </div>
    </Fragment>
  );
}
