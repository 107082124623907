/** @jsx jsx */
import {jsx} from '@emotion/core';
import React from 'react';

import {AuthConsumer} from '../AuthContext';
import SearchPanel from '../Home/Index';
import Layout from '../Layout/Layout';
import NoAccessPanel from '../Home/NoAccessPanel';


export default class Home extends React.Component {
  render() {
    return (
        <AuthConsumer>
          {({user}) => (
              <Layout>
                {user.name && (user.roles.length === 0 ? <NoAccessPanel user={user}/> : <SearchPanel user={user}/>)}
              </Layout>
          )}
        </AuthConsumer>
    );
  }
}
