/** @jsx jsx */
import { jsx } from '@emotion/core';

const noResultsStyle = {
  fontSize: 24,
  textAlign: 'center',
  marginTop: 74,
};

export default function NoAccessPanel() {
  return <div css={noResultsStyle}>Sorry! You don't have permissions to view this app! Please contact IT.</div>;
}
