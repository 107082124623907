/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { Component } from 'react';
import SearchInput from '../ui/Inputs/SearchInput';
import Button from '../ui/Button';
import SearchAdvisoryPanels from './SearchAdvisoryPanels';
import NoResultsPanel from './NoResultsPanel';
import SearchResultsPanel from './SearchResultsPanel';
import AddNewAccountForm from '../Forms/AddNewAccountForm';
import { adalApiFetch } from '../AdalConfig';
import { userRoles } from '../../Utils/Roles';

// #region css
const innerWrap = {
  maxWidth: 1144,
  padding: '0 24px',
  margin: '104px auto',
  position: 'relative',
  zIndex: 10,
  '@media(min-width: 768px)': {
    margin: '152px auto',
  },
  '@media print': { marginTop: 0, padding: 0 },
};

const inputWrap = {
  display: 'inline-flex',
  marginRight: 24,
  width: '100%',
  '@media(min-width: 768px)': {
    width: 'calc(100% - 280px)',
  },
};

const buttonStyle = {
  height: 72,
  display: 'none',
  '@media(min-width: 768px)': {
    display: 'inline-flex',
  },
  '@media print': { display: 'none !important' },
};

// #endregion

const searchStatuses = {
  advisoryPanels: 'ADVISOTY_PANELS',
  noResultsPanel: 'NO_RESULTS_PANEL',
  resultsPanel: 'RESULTS_PANEL',
  addAccountPanel: 'ADD_ACCOUNT_PANEL',
};

const searchOptions = {
  accountNumber: 'ACCOUNT_NUMBER',
  payee: 'PAYEE',
};

const getAccounts = async (searchString, searchBy) => {
  const encodedParam = encodeURIComponent(searchString);
  const url =
    searchBy === searchOptions.accountNumber
      ? `api/Accounts/SearchAccountsOrFurtherCredit?searchQuery=${encodedParam}`
      : `api/Accounts/SearchAccountsByPayee?payee=${encodedParam}`;
  const { data: accountsInfo } = await adalApiFetch(axios.get, url, {});
  return accountsInfo;
};

export default class extends Component {
  state = {
    accountsInfo: [],
    searchStatus: searchStatuses.advisoryPanels,
    searchString: '',
    isLoading: false,
    accountNumber: '',
    isAdmin: '',
  };

  componentDidMount() {
    const { user } = this.props;
    this.setState({ isAdmin: user.roles.includes(userRoles.admin) });
  }

  handleSubmit = async () => {
    let { searchString } = this.state;
    searchString = searchString.replace(/-/g, '').trim();
    const isSearchStringNumeric = !Number.isNaN(+searchString);
    const searchBy = isSearchStringNumeric ? searchOptions.accountNumber : searchOptions.payee;
    this.setState({
      isLoading: true,
      searchString,
      accountNumber: isSearchStringNumeric ? searchString : '',
    });
    const accountsInfo = await getAccounts(searchString, searchBy);
    this.setState({ accountsInfo, isLoading: false });
    this.updateSearchStatus();
  };
  updateSearchStatus = () => {
    this.setState(prevState => {
      if (!prevState.accountsInfo.length && !prevState.searchString) {
        return { searchStatus: searchStatuses.advisoryPanels };
      }
      if (!prevState.accountsInfo.length) {
        return { searchStatus: searchStatuses.noResultsPanel };
      }
      return { searchStatus: searchStatuses.resultsPanel };
    });
  };

  onInputChange = e => {
    this.setState({
      searchStatus: searchStatuses.advisoryPanels,
      searchString: e.target.value,
    });
  };

  handleChange = accountID => {
    if (accountID) {
      this.setState(prevState => ({
        accountsInfo: prevState.accountsInfo.filter(i => i.id !== accountID),
      }));
    }
    this.updateSearchStatus();
  };

  onAddNewAccount = () => {
    this.setState({ searchString: '', searchStatus: searchStatuses.addAccountPanel });
  };

  renderSearchResults = () => {
    const { accountsInfo, searchString, accountNumber, isAdmin, searchStatus } = this.state;
    const { user } = this.props;

    switch (searchStatus) {
      case searchStatuses.advisoryPanels:
        return <SearchAdvisoryPanels />;
      case searchStatuses.noResultsPanel:
        return <NoResultsPanel searchString={searchString} />;
      case searchStatuses.resultsPanel:
        return accountsInfo.map(info => {
          return (
            <SearchResultsPanel
              info={info}
              isAdmin={isAdmin}
              key={info.id}
              handleChange={this.handleChange}
              accountNumber={info.accountNumber}
            />
          );
        });
      case searchStatuses.addAccountPanel:
        return <AddNewAccountForm handleChange={this.handleChange} user={user} />;
      default:
        return null;
    }
  };

  render() {
    const { searchString, isLoading, isAdmin } = this.state;
    return (
      <div css={innerWrap}>
        <SearchInput
          searchIcon
          value={searchString || ''}
          onChange={e => this.onInputChange(e)}
          styleOverrides={[{ height: 72 }, isAdmin && inputWrap]}
          placeholder="Search account number, further credit or payee"
          handleSubmit={this.handleSubmit}
          {...isLoading && { isLoading }}
        />
        {isAdmin && (
          <Button styleoverrides={buttonStyle} onClick={this.onAddNewAccount}>
            Add new Account
          </Button>
        )}
        {this.renderSearchResults()}
      </div>
    );
  }
}
