/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ProjectVerifyIcon } from '../ui/icons';

const baseStyles = {
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
};

export default function Logo({ styleoverrides }) {
  const mergedStyles = [baseStyles, styleoverrides && styleoverrides];
  return (
    <div css={mergedStyles}>
      <ProjectVerifyIcon />
    </div>
  );
}
